import React from 'react';
import '../confirmationModel/confirmationmodel.css'
interface ConfirmationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    message: string;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpen, onClose, onConfirm, message }) => {
    if (!isOpen) return null;
    return (
        <div className='confirmation'>
            <div className='logout-confirm'>
                <span className='logout-text'>Are you sure you want to proceed?</span>
                <span className='logout-info'>{message}</span>
                <div className='buttons'>
                    <button className='cancel' onClick={onClose}>Cancel</button>
                    <button className='proceed'onClick={onConfirm}>Proceed</button>
                </div>
            </div>
        </div>
    );
};
