import React from "react";
import "./metrics.css";
import adminIcon from "../../assets/3Userdashboard.png"; 
import userIcon from "../../assets/3Userdashboard.png";
import licenseIcon from "../../assets/wrkspaceicon.png";
import migratorIcon from "../../assets/migrator.png";
import { getSessionItem } from "../helper";
import { DataItem } from "../interfaces";

interface Metric {
  id: number;
  title: string;
  value: number;
  icon: string;
}


const Metrics: React.FC = () => {
  const lMetrics = getSessionItem('lMetrics');
  const parseData = lMetrics && JSON.parse(lMetrics);
  const slackWorkSpaceData= getSessionItem('slackWorkSpaceData');
  const response: DataItem[] = slackWorkSpaceData? JSON.parse(slackWorkSpaceData):[];
  const uniqueWorkspaces = Array.from(
      new Set(response.map((item) => item.workspaceName))
    );
  const WorkspaceLength = slackWorkSpaceData && JSON.parse(slackWorkSpaceData);
  

  const metricsData: Metric[] = [
    { id: 1, title: "Total Licenses", value: parseData?.totalLicenses===-1? "Unlimited":parseData? parseData.totalLicenses:0, icon: adminIcon },
    { id: 2, title: "Assigned Licenses", value: parseData?.assignedLicenses===-1? "Unlimited":parseData? parseData.assignedLicenses:0, icon: licenseIcon },
    { id: 3, title: "Consumed Licenses", value: parseData?.consumedLicenses ? parseData.consumedLicenses:0, icon: userIcon },
    { id: 4, title: "Available Licenses", value: parseData?.availableLicenses===-1? "Unlimited":parseData? parseData.availableLicenses:0, icon: adminIcon },
    { id: 5, title: "Connected Workspaces", value: uniqueWorkspaces?uniqueWorkspaces.length :0, icon: migratorIcon },
  ];


  return (
    <div className="metrics-container">
      {metricsData && metricsData.map((metric) => (
        metric &&
        <div className="metric-card" key={metric.id}>
            <div className="metric-text">
                <img src={metric.icon} alt={`${metric.title} Icon`} className="metric-icon" />
                <h3>{metric.title}</h3>
            </div>
            <p className="metric-value">{metric.value}</p>
        </div>
      ))}
    </div>
  );
};

export default Metrics;
