import { useContext, useEffect, useState } from 'react';
import teams from '../../../assets/teams.png'
import { GlobalStateContext } from '../../../common/GlobalState';
import SpinnerGif from '../../../assets/saketa-loading.gif'
import { SLACKAUTH } from '../../../common/constants';
import microsoftlogo from '../../../assets/microsoftlogo.png';
import './teamslogin.css'
import successgif from '../../../assets/success.gif';
import { TEAMSACCOUNT } from '../../../common/ApiConfig';
import { generateOAuthUrl, TeamsloginUser } from '../../../common/authServices';
import { getSessionItem,  setSessionItem,checkTokenExpiration } from '../../../common/helper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DataItem } from '../../../common/interfaces';

const TeamsLogin: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { currentStep, setCurrentStep} = useContext(GlobalStateContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<any>('');
    const [domainUsers, setDomainUsers] = useState<string[]>([]);
    const [selectedUserName, setSelectedUserName] = useState<string>("");
    const [dropdownError, setDropdownError] = useState<any>('');
        
    const handleTeamsAccountLogin = async () => {
        try {
            const oauthUrl = generateOAuthUrl(TEAMSACCOUNT.CLIENT_ID, TEAMSACCOUNT.RESPONSE_TYPE, TEAMSACCOUNT.SCOPES, TEAMSACCOUNT.PROMPT,TEAMSACCOUNT.REDIRECT_URI);
            window.location.href = oauthUrl;
        }
        catch (error) {
            console.error('Error during login:', error);
        }
    };
    const handleTeamLogin =async (code:string)=>{
        setIsLoading(true);
        const state = null;
        const token = getSessionItem('bearerToken');
        const adminData= getSessionItem('adminData');
        const storedData =JSON.parse(adminData);
        try {
            const teamsResponse = await TeamsloginUser(token, code,storedData,state);
            const responseData = await teamsResponse.json();
            if (responseData.exception == null) {               
                const modifiedData = {
                    ...responseData.data, 
                    tenantID: responseData.data.organizationID, 
                    organizationID: undefined
                };   
                setSessionItem("TeamLoginData",modifiedData);
                const slackData = {
                    email: responseData.data.email,
                    tenantID: responseData.data.organizationID,
                    userName: responseData.data.userName,
                    userID:  responseData.data.userID
                }  
                const slackWorkSpaceData= getSessionItem('teamDomainData');
                setCurrentStep(SLACKAUTH.TEAMSSUCCESS);
                const parseData = slackWorkSpaceData && JSON.parse(slackWorkSpaceData);
                if(parseData.length===0){
                    const slackWorkspace = [slackData];
                    setSessionItem('teamDomainData',slackWorkspace)
                }
                else{
                    if (parseData && parseData.length > 0) {
                        let addedWorkspace = [...parseData];                    
                        const isExisting = addedWorkspace.some(
                            (user: any) =>{
                                if(user.userName === slackData.userName){
                                    addedWorkspace.push(slackData);
                                }                                
                            }
                        );                    
                        if (!isExisting) {
                            addedWorkspace.push(slackData); 
                        }                    
                        setSessionItem('teamDomainData', JSON.stringify(addedWorkspace));
                    }    
                }
            }
            else{
                setCurrentStep(SLACKAUTH.TEAMSLOGIN);
                setError(responseData)
            }
        }
        catch(error:any){
            setCurrentStep(SLACKAUTH.TEAMSLOGIN);
            setError(error)
        }
        finally{
            setIsLoading(false);
        }
    }
    useEffect(() => {
        const error = searchParams.get('error');
        if (error) {
            navigate('/dashboard');
            setError(error);
            setCurrentStep(SLACKAUTH.TEAMSLOGIN);
            return
        }
        const code = searchParams.get('code');
        const session_state = searchParams.get('session_state');
        const isGenerateSASKey= getSessionItem('isGenerateSASKey')
        if (!isGenerateSASKey &&session_state && code) {
            if (session_state !== null)
                handleTeamLogin(code);
        }
    }, [searchParams]);


    const handleCurrentGifLoad = () => {
        setTimeout(() => {
            navigate('/dashboardchannel')
        }, 2000);

    };
    const handleTeamsDomain = async(userName: string) =>{
        const slackWorkSpaceData= getSessionItem('teamDomainData');
        const admindata = getSessionItem('adminData');
        const parseAdmindata = admindata ?JSON.parse(admindata):''
        const parseData = slackWorkSpaceData ? JSON.parse(slackWorkSpaceData):'';
        const filteredUsers = parseData ? parseData.filter((user: { workspaceName: string; userName: string; }) => {
            return (
                    user.userName ==  userName
                );
            }): []
        if(filteredUsers.length>0){
            let x={...filteredUsers[0],tenantID:parseAdmindata.tenantID}
            setSessionItem("TeamLoginData",x);
            setCurrentStep(SLACKAUTH.TEAMSSUCCESS);
            setSelectedUserName("");
        }       
        else{
            setDropdownError("please select the userName");
        } 
    }
    useEffect(() => {
        const teamDomainData= getSessionItem('teamDomainData');
        const response: DataItem[] = teamDomainData && teamDomainData? JSON.parse(teamDomainData):'';
        if(response){
            const uniqueUserName = Array.from(
                new Set(response.map((item: { userName: string; }) => item.userName))
                );
            setDomainUsers(uniqueUserName);
        }
       
    }, []);
    useEffect(() => {
        const bearerToken = getSessionItem('bearerToken')
        if (bearerToken) {
            const token = bearerToken;
            const istokenValid = checkTokenExpiration(token)
            if (!istokenValid) {
               sessionStorage.clear()
               navigate('/')
            }
        }
    })
    const handleWorkspaceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedUserName(event.target.value);
        setDropdownError('');
    }
        
    return (
        <>
            {isLoading && (
                <div className="spinner-container">
                    <img
                        src={SpinnerGif}
                        alt="Loading..."
                        className="spinner-gif"

                    />
                </div>
            )}
            {(currentStep === SLACKAUTH.TEAMSLOGIN ) &&
                <>  
                    <div className='teams-container'>
                        <div className="teams-login-container">
                            {domainUsers.length>0 &&<> 
                            <span style={{textAlign:'center',fontSize:'0.8rem',fontWeight:'bold'}}> Login with available Users</span>
                            <div className='domainData'>
                            <select
                                id="workspace"
                                value={selectedUserName} 
                                className="username"
                                onChange={handleWorkspaceChange } 
                            >
                                <option value="">Select User</option>
                                {domainUsers.map((workspace) => (
                                    <option key={workspace} value={workspace} className="key-box" title={workspace}>
                                        {workspace.length>18 ? workspace.slice(0,18)+'...':workspace}
                                    </option>))}
                            </select>   
                                <span><button className='connect' onClick={() => handleTeamsDomain(selectedUserName)}>Connect</button></span>                            
                            </div>
                            <span>{dropdownError !== '' ? <div className="slack-error-msg">{dropdownError}</div> : ''}</span>
                            <div className="separator">OR</div>
                            </>}
                            <span className='signin'>Sign in to
                                <img src={teams} alt="slack" className="teamslogo" />
                                <span className='slack'>Teams</span>
                            </span>
                        <div className='slacktext'>Login with your Teams admin account</div>
                            <button onClick={handleTeamsAccountLogin} className="slackbutton"><img src={microsoftlogo} alt="microsoft-logo" className="microsoftlogo" /> Login with your M365 Account</button>
                            <span>{error !== '' ? <div className="slack-error-msg">{error?.message}</div> : ''}</span>

                        </div>
                    </div>
                </>
            }
            {currentStep === SLACKAUTH.TEAMSSUCCESS &&
                <div className='slackloginsuccess'>
                    <img src={successgif} alt="success gif" className='slacksuccessgif' onLoad={
                        handleCurrentGifLoad} />
                    <span className='successText'>Teams Login Successful</span>
                </div>
            }
        </>
    );
}
export default TeamsLogin;

