import saketa from "../../assets/saketa.png";
import '../header/header.css';
import { useNavigate, useSearchParams } from "react-router-dom";
import { getInitials, getSessionItem, setSessionItem } from "../helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket, faXmark, faKey, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { ConfirmationModal } from "../../components/confirmationModel/confirmatiommodel";
import { useContext, useEffect, useState } from "react";
import { checkTokenExpiration } from "../helper";
import { GlobalStateContext } from "../GlobalState";
import spinnerGif from "../../assets/saketa-loading.gif";
import { useLocation } from "react-router-dom";
import { AUTH } from "../constants";
import { authenticateAzureAdmin, generateOAuthUrl, generateSASKey } from "../authServices";
import { ADMINACCOUNT, SLACKACCOUNT, TEAMSACCOUNT } from "../ApiConfig";



const Header = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { setCurrentStep } = useContext(GlobalStateContext);
    const [migrationKey, setMigrationKey] = useState<string>('');
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [blobAccountName, setBlobAccountName] = useState<string>('');
    const [isBlobAccount, setIsBlobAccount] = useState<boolean>(false);
    const [error, setError] = useState<any>('')
    const [searchParams] = useSearchParams();
    const location = useLocation();
    useEffect(() => {
        const enterpriseAccount = getSessionItem('isEnterpriseAccount')
        if (!enterpriseAccount && location.pathname !== "/dashboardchannel") {
            setIsBlobAccount(true);
            const blobAccount = getSessionItem('blobAccount');
            setBlobAccountName(blobAccount);
        }
    }, [])
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const confirmAction = () => {
        sessionStorage.clear();
        setCurrentStep(AUTH.ADMINLOGIN)
        navigate('/');
    }
    const copyToClipboard = () => {
        navigator.clipboard.writeText(migrationKey).then(() => {
            alert('Migration Key copied to clipboard!');
        });
    };
    const handleAzureAccountLogin = async () => {
        try {
            setSessionItem('isGenerateSASKey', true);
            // const teamsLoginData =  getSessionItem('TeamLoginData');
            // const redirectURI= teamsLoginData?process.env.REACT_APP_DASHBOARD_REDIRECT_URI:SLACKACCOUNT.REDIRECT_URI;
            const oauthUrl = generateOAuthUrl(ADMINACCOUNT.CLIENT_ID, ADMINACCOUNT.RESPONSE_TYPE, TEAMSACCOUNT.SCOPES, TEAMSACCOUNT.PROMPT, SLACKACCOUNT.REDIRECT_URI);
            // if(oauthUrl)
            window.location.href = oauthUrl;
        }
        catch (error) {
            console.error('Error during login:', error);
        }
    }
    useEffect(() => {
        const code = searchParams.get('code');
        const isGenerateSASKey = getSessionItem('isGenerateSASKey');
        const error = searchParams.get('error');
        if (error && isGenerateSASKey) {
            setError(error);
            setShowPopup(true)
            return
        }
        if (code && isGenerateSASKey) {
            handleAzureAccount(code)
        }
    }, [searchParams]);

    const handleAzureAccount = async (loginCode: string) => {
        const bearerToken = getSessionItem('bearerToken')
        if (bearerToken !== '') {
            const token = bearerToken;
            const istokenValid = checkTokenExpiration(token);
            if (istokenValid) {
                try {
                    setIsLoading(true);
                    const storageAccountDetails = await generateSASKey(loginCode, bearerToken)
                    const response = await storageAccountDetails.json();
                    if (response) {
                        setMigrationKey(response.data);
                        setShowPopup(true)
                    }

                }

                catch (error: any) {
                    setError(error?.message?.length > 200 ? error.message.slice(0, 110) + '...' : error.message);
                    setShowPopup(true)
                }
                finally {
                    setSessionItem('isGenerateSASKey', false);
                    setIsLoading(false);
                }
            }
        }

    }
    const handleProfileClick = () => {
        setShowPopup(!showPopup);
        setMigrationKey('')
        setError('')

    }
    const adminData = getSessionItem('adminData');
    const parsedata = JSON.parse(adminData);
    var avatar = ""
    if (parsedata) {
        avatar = getInitials(parsedata.userName)
    }
    useEffect(() => {
        const bearerToken = getSessionItem('bearerToken')
        if (bearerToken) {
            const token = bearerToken;
            const istokenValid = checkTokenExpiration(token)
            if (!istokenValid) {
                sessionStorage.clear()
                navigate('/')
            }
        }
    })
    return (
        <>
            {isLoading && (
                <div className="spinner-container">
                    <img
                        src={spinnerGif}
                        alt="Loading..."
                        className="spinner-gif"

                    />
                </div>
            )}
            <header>
                <div className="headercontent">
                    <img src={saketa} alt="slack" className="saketaicon" />
                    <span className='saketatext'>Saketa</span>
                </div>
                <div className="migration-profile">
                    {isBlobAccount &&
                        <div className="migration" onClick={() => { handleProfileClick() }}>
                            <FontAwesomeIcon icon={faKey} />
                            <label>Key</label>
                        </div>
                    }
                    <div className="Profilelogo">
                        <span className="avatar" >{avatar} </span>
                        <span>{parsedata?.userName} </span>
                        <span><FontAwesomeIcon icon={faRightFromBracket} onClick={() => setIsModalOpen(true)} style={{ cursor: "pointer" }} /></span></div>
                </div>
            </header>


            {showPopup && (
                <div className='storage'>
                    <div className="token-popup">
                        <FontAwesomeIcon icon={faXmark} onClick={() => setShowPopup(false)} height={30} width={30} className="closeIcon" />

                        <div className="popup-line">
                            <label className="account-name"><label style={{fontWeight:600,fontSize:"13px"}}>Storage Account:</label> &nbsp; {blobAccountName}</label>
                        </div>


                        {migrationKey !== '' ? (
                            <div className="popup-line">
                                <div className="migration-container">
                                <label className="migration-title">Migration Key: </label> 
                                <span className="TooltipMigration">
                                        <FontAwesomeIcon icon={faCircleInfo} className="infoIcon" />
                                        <span className="tooltiptext-migration">Set up the SAS key in the migrator tool to enable the connection
                                        </span>
                                    </span>
                                    </div>
                                <div className="key-container">
                                    <label
                                        className="key-box"
                                        title={migrationKey}
                                    >
                                        {migrationKey}
                                    </label>
                                </div>

                                <button onClick={copyToClipboard} className="copy-btn" style={{marginLeft:0}}>
                                    Copy
                                </button>
                            </div>
                        ) : <button
                            onClick={handleAzureAccountLogin}
                            className="generate-token-btn"
                        >
                            Generate Token
                        </button>}

                        {error !== '' ? <div className="key-error-msg">{error}</div> : ''}
                    </div>
                </div>
            )
            }

            <ConfirmationModal
                isOpen={isModalOpen}
                onClose={closeModal}
                onConfirm={confirmAction}
                message={'Clicking on "Proceed" will log you out of your account.'} />

        </>
    )
}
export default Header;