// authService.ts
import { APIEMAILUSER, APITEMPUSER, APIURL } from "./ApiConfig";
import { admin, SubscriptionData, TeamUsers } from "./interfaces";

export const generateOAuthUrl = (clientId: string, responseType: string, scopes: string, prompt: string, redirect_uri: string) => {
    const baseUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
    const url = `${baseUrl}?client_id=${clientId}&response_type=${responseType}&redirect_uri=${encodeURIComponent(redirect_uri)}&scope=${scopes}&prompt=${prompt}`;
    return url;
};

export const authenticateAdmin = async (loginCode: string, api: string, state: string | null) => {
    const jsonData = {
        authCode: loginCode,
        state: state
    };
    const adminResponse = await fetch(`${api}/Authenticator/GenerateSaketaToken`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
    });

    if (!adminResponse.ok) {
        const errorResponse = await adminResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${adminResponse.status}`);
    }
    return adminResponse;
};
export const authenticateTempUser = async (loginCode: string) => {
    const api = APITEMPUSER;
   

    const jsonData = {
        authCode: loginCode,
        state: null
    };
    const adminResponse = await fetch(`${api}/Authenticator/GenerateSaketaToken`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(jsonData),
    });

    if (!adminResponse.ok) {
        const errorResponse = await adminResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${adminResponse.status}`);
    }
    return adminResponse;
};
export const authenticateAzureAdmin = async (loginCode: string) => {
    const api = APITEMPUSER;
    const jsonData = {
        authCode: loginCode,
        state: null
    };
    const adminResponse = await fetch(`${api}/Authenticator/GenerateSaketaToken`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
    });

    if (!adminResponse.ok) {
        const errorResponse = await adminResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${adminResponse.status}`);
    }
    return adminResponse;
};
//userAPI
export const authenticateEmailUser = async (loginCode: string, state: string ) => {
    const api = APIEMAILUSER;
    const jsonData = {
        authCode: loginCode,
        state: state
    };
    const adminResponse = await fetch(`${api}/Authenticator/GenerateSaketaToken`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
    });

    if (!adminResponse.ok) {
        const errorResponse = await adminResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${adminResponse.status}`);
    }
    return adminResponse;
};

//Teams API
export const TeamsloginUser = async (token: string, loginCode: string, storedData: admin, state: string | null) => {
    const jsonData = {
        authCode: loginCode,
        state: state
    };
    const teamsloginresponse = await fetch(`${APIURL}/Authenticator/GenerateGraphToken`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            userId: `${storedData.userID}`,
            orgId: `${storedData.tenantID}`,
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(jsonData)
    });
    if (!teamsloginresponse.ok) {
        const errorResponse = await teamsloginresponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${teamsloginresponse.status}`);
    }
    return teamsloginresponse;
}

//Fetch API
export const fetchTeamUsers = async (token: string, storedData: any, teamData: any) => {
    const teamsUserResponse = await fetch(`${APIURL}/Admin/FetchAllMSUsers`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            UserId: `${teamData.userID}`,
            OrgId: `${storedData.tenantID}`,
            Authorization: `Bearer ${token}`
        }
    });
    if (!teamsUserResponse.ok) {
        const errorResponse = await teamsUserResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${teamsUserResponse.status}`);
    }
    return teamsUserResponse;
}

export const configureOrg = async (storageAccount: string, token: string, adminData: admin,subId:string,resName:string) => {
    const requestData = {
        isStorageAccount: true,
        storageAccount: storageAccount,
        SubscriptionId:subId,
        ResourceGrpName:resName

    };
    const response = await fetch(`${APIURL}/Admin/ConfigureOrg`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            UserID: `${adminData.userID}`,
            OrgID: ` ${adminData.tenantID}`,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData)
    });
    if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${response.status}`);
    }

    return response;
};
export const fetchAddUsers = async (token: string, teamData: any) => {
    const teamsResponse = await fetch(`${APIURL}/Admin/GetNonSaketaMSUsers`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            UserID: `${teamData.userID}`,
            OrgID: `${teamData.tenantID}`,
            Authorization: `Bearer ${token}`,
        }
    });
    if (!teamsResponse.ok) {
        const errorResponse = await teamsResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${teamsResponse.status}`);
    }

    return teamsResponse;
};

export const getDefaultTeamUsers = async (token: string, storedData: any, teamData: admin) => {
    const DataResponse = await fetch(`${APIURL}/Admin/GetSaketaMSUsers`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            UserID: `${teamData.userID}`,
            OrgID: `: ${storedData.tenantID}`,
            Authorization: `Bearer ${token}`,
        }
    });

    if (!DataResponse.ok) {
        const errorResponse = await DataResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${DataResponse.status}`);
    }

    return DataResponse;
}
//Slack Users
export const fetchAddSlackUsers = async (adminData: any, token: string) => {
    const teamsResponse = await fetch(`${APIURL}/Admin/GetNonSaketaSlackUsers`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            UserID: `${adminData.userID}`,
            OrgID: `${adminData.workspaceID}`,
            Authorization: `Bearer ${token}`,
        }
    });

    if (!teamsResponse.ok) {
        const errorResponse = await teamsResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${teamsResponse.status}`);
    }

    return teamsResponse;
};

export const getDefaultSlackUsers = async (token: string, storedData: any) => {
    const DataResponse = await fetch(`${APIURL}/Admin/GetSaketaSlackUsers`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            UserID: `${storedData.userID}`,
            OrgID: `${storedData.workspaceID}`,
            Authorization: `Bearer ${token}`,
        }
    });

    if (!DataResponse.ok) {
        const errorResponse = await DataResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${DataResponse.status}`);
    }

    return DataResponse;
}

export const fetchSlackUsers = async (token: string, storedData: any) => {
    const teamsUserResponse = await fetch(`${APIURL}/Admin/FetchAllSlackUsers`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            UserId: `${storedData.userID}`,
            OrgId: `${storedData.workspaceID}`,
            Authorization: `Bearer ${token}`
        }
    });
    if (!teamsUserResponse.ok) {
        const errorResponse = await teamsUserResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${teamsUserResponse.status}`);
    }

    return teamsUserResponse;
}


export const getRegions = async (storedData:any) => {

  
    const DataResponse = await fetch(`${APIURL}/Admin/GetRegions`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            UserID: `${storedData.userID}`,
            OrgID: `${storedData.tenantID}`,
            Authorization: `Bearer ${storedData.token}`,
        }
    });

    if (!DataResponse.ok) {
        const errorResponse = await DataResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${DataResponse.status}`);
    }

    return DataResponse;
}

//Slack Login
export const SlackloginUser = async (token: string, loginCode: string, storedData: any, state: string | null) => {
    const jsonData = {
        authCode: loginCode,
        state: state
    };
    const slackLoginResponse = await fetch(`${APIURL}/Authenticator/GenerateSlackToken`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            userId: `${storedData.userID}`,
            orgId: `${storedData.tenantID}`,
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(jsonData)
    });
    if (!slackLoginResponse.ok) {

        const errorResponse = await slackLoginResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${slackLoginResponse.status}`);
    }
    return slackLoginResponse;
}
export const fetchAddSaketaSlackUsers = async (token: string, storedData: any, users: TeamUsers[]) => {
    let requestData: any[] = []
    if (users.length === 1) {
        requestData = [{
            userName: users[0].userName,
            email: users[0].email
        }];
    }
    else {
        requestData = users;
    }

    const response = await fetch(`${APIURL}/Admin/AddSaketaSlackUsers`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            UserID: `${storedData.userID}`,
            OrgID: ` ${storedData.workspaceID}`,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData)
    });
    if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${response.status}`);
    }

    return response;
}
export const fetchAddSaketaTeamUsers = async (token: string, storedData: any, users: any[]) => {

    let requestData: any[] = []
    if (users.length === 1) {
        requestData = [{
            userName: users[0].userName,
            email: users[0].email
        }];

    }
    else {
        requestData = users
    }
    const response = await fetch(`${APIURL}/Admin/AddSaketaTeamsUsers`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            UserID: `${storedData.userID}`,
            OrgID: ` ${storedData.tenantID}`,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData)
    });
    if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${response.status}`);
    }

    return response;
}

export const sendEmailsForSlack = async (token: string, storedData: any, users: any[]) => {
    const DataResponse = await fetch(`${APIURL}/Admin/SendEmailsForSlack`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            UserID: `${storedData.userID}`,
            OrgID: `${storedData.workspaceID}`,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(users)
    });
    if (!DataResponse.ok) {
        const errorResponse = await DataResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${DataResponse.status}`);
    }


    return DataResponse;
}
export const sendEmailsForTeams = async (token: string, storedData: any, users: any[]) => {
    const DataResponse = await fetch(`${APIURL}/Admin/SendEmailsForTeams`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            UserID: `${storedData.userID}`,
            OrgID: `${storedData.tenantID}`,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(users)
    });
    if (!DataResponse.ok) {
        const errorResponse = await DataResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${DataResponse.status}`);
    }

    return DataResponse;
}
export const assignLicenses = async (token: string, storedData: any, licensedUsers: any[]) => {
    const users = {
        licensedUsers: licensedUsers
    };
    const DataResponse = await fetch(`${APIURL}/Admin/AssignLicenses`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            UserID: `${storedData.userID}`,
            OrgID: `${storedData.workspaceID}`,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(users)
    });
    if (!DataResponse.ok) {
        const errorResponse = await DataResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${DataResponse.status}`);
    }

    return DataResponse;
}
export const removeLicenses = async (token: string, storedData: any, licensedUsers: any[]) => {
    const users = {
        licensedUsers: licensedUsers
    };
    const DataResponse = await fetch(`${APIURL}/Admin/RemoveLicenses`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            UserID: `${storedData.userID}`,
            OrgID: `${storedData.workspaceID}`,
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(users)
    });
    if (!DataResponse.ok) {
        const errorResponse = await DataResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${DataResponse.status}`);
    }

    return DataResponse;
}
export const revokeSlackRequest = async (token: string, storedData: any, licensedUsers: any[]) => {

    const requestBody = JSON.stringify({
        licensedUsers: licensedUsers,
    });
    const DataResponse = await fetch(`${APIURL}/Admin/RevokeSlackRequest`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            UserID: `${storedData.userID}`,
            OrgID: `${storedData.workspaceID}`,
            Authorization: `Bearer ${token}`,
        },
        body: requestBody
    });
    if (!DataResponse.ok) {
        const errorResponse = await DataResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${DataResponse.status}`);
    }


    return DataResponse;
}
export const revokeTeamsRequest = async (token: string, storedData: any, licensedUsers: any[]) => {
    const requestBody = JSON.stringify({
        licensedUsers: licensedUsers,
    });
    const DataResponse = await fetch(`${APIURL}/Admin/RevokeTeamsRequest`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            UserID: `${storedData.userID}`,
            OrgID: `${storedData.tenantID}`,
            Authorization: `Bearer ${token}`,
        },
        body: requestBody
    });
    if (!DataResponse.ok) {
        const errorResponse = await DataResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${DataResponse.status}`);
    }

    return DataResponse;
}
//Reject slack API
export const RejectAPI = async (token: string, storedData: any, state: string | null) => {
    const jsonData = {
        state: state,
        userId: storedData.userID
    };
    const slackLoginResponse = await fetch(`${APIURL}/Admin/RejectRequest`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            userId: `${storedData.userID}`,
            orgId: `${storedData.tenantID}`,
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(jsonData)
    });
    if (!slackLoginResponse.ok) {
        const errorResponse = await slackLoginResponse.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${slackLoginResponse.status}`);
    }

    return slackLoginResponse;
}
//activity feed

export const fetchActivityInfo = async (token: string, channelType: number,storedData:any) => {
    try {
        const jsonData = {
            requestorId: storedData.userID,
            channelType: channelType
        };
        const response = await fetch(`${APIURL}/Admin/GetActivityFeeds`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                userId: `${storedData.userID}`,
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(jsonData)
        });
 
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
};
export const GetResourceGroup = async (storedData: any, SubscriptionId: string) => {
    const jsonData = {
        subscriptionId: SubscriptionId
    };
    const response = await fetch(`${APIURL}/Admin/GetResourceGroupsBySubscription`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            userId: `${storedData.userID}`,
            orgId: `${storedData.tenantID}`,
            Authorization: `Bearer ${storedData.token}`
        },
        body: JSON.stringify(jsonData)
    });
    if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${response.status}`);
    }
    return response;
}
export const addResourceGroup = async (storedData: any, SubscriptionId: string, resourceGroupName: string,region:string) => {
    const jsonData = {
        subscriptionId: SubscriptionId,
        resourceGrpName: resourceGroupName,
        region:region
    };
    const response = await fetch(`${APIURL}/Admin/AddResourceGroup`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            userId: `${storedData.userID}`,
            orgId: `${storedData.tenantID}`,
            Authorization: `Bearer ${storedData.token}`
        },
        body: JSON.stringify(jsonData)
    });
    if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${response.status}`);
    }
    return response;
}
export const configureOrganization = async (storedData: any, SubscriptionId: string,resourceGroupName:string,blobAccount:string) => {
    const jsonData = {
            subscriptionId: SubscriptionId,
            resourceGroupName: resourceGroupName,
            storageAccountName: blobAccount,
            principalId:storedData.principalId
    };
    const response = await fetch(`${APIURL}/Admin/ConfigurationSetUp`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            userId: `${storedData.userID}`,
            orgId: `${storedData.tenantID}`,
            Authorization: `Bearer ${storedData.token}`
        },
        body: JSON.stringify(jsonData)
    });
    if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${response.status}`);
    }
    return response;
}

export const addStorageAccountName = async (storedData: any, SubscriptionId: string, resourceGroupName: string, storageAccountName: string,region:string) => {
    const jsonData = {

        subscriptionId: SubscriptionId,
        resourceGroupName: resourceGroupName,
        storageAccountName: storageAccountName,
        region:region

    };
    const response = await fetch(`${APIURL}/Admin/AddStorageAccount`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            userId: `${storedData.userID}`,
            orgId: `${storedData.tenantID}`,
            Authorization: `Bearer ${storedData.token}`
        },
        body: JSON.stringify(jsonData)
    });
    if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${response.status}`);
    }
    return response;
}
export const generateSASKey=async (loginCode:string,token:string)=>{
    const jsonData = {
       authCode:loginCode
    };
    
    const response = await fetch(`${APIURL}/Admin/GenerateSASKey`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(jsonData)
    });
    if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${response.status}`);
    }
    return response;
}
export const GetBlobAccount = async (storedData: any, resourceId: string) => {
    const jsonData = {
        resourceGroupId: resourceId
    };
    const response = await fetch(`${APIURL}/Admin/GetStorageAccountsByResourceGroup`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            userId: `${storedData.userID}`,
            orgId: `${storedData.tenantID}`,
            Authorization: `Bearer ${storedData.token}`
        },
        body: JSON.stringify(jsonData)
    });
    if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.msg || `HTTP error! status: ${response.status}`);
    }
    return response;
}


// export const getDefaultSlackUsers = async ( token: string, storedData:any) => {
//     const DataResponse = await fetch(`${APIURL}/Admin/GetSaketaMSUsers`, {
//         method: 'GET',
//         headers: {
//             'Content-Type': 'application/json',
//             UserID: `${storedData.userID}`,
//             OrgID: `: ${storedData.tenantID}`,
//             Authorization: `Bearer ${token}`,
//         }
//     });

//     if (!DataResponse.ok) {
//         throw new Error(`HTTP error! status: ${DataResponse.status}`);
//     }

//     return DataResponse;
// }



// SLack fetch users API
// export const fetchSlackUsers = async (token: string | null, slackUserId: string, slackUserEmailId: string, adminUserEmail: string) => {
//     if (slackUserId && slackUserEmailId) {
//         const jsonData = {
//             UserId: slackUserId,
//             UserEmailId: slackUserEmailId,
//             AdminUserEmailId: adminUserEmail
//         }
//         const response = await fetch(`${APIURL}/api/slacks/fetch-slack-users`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: `Bearer ${token}`
//             },
//             body: JSON.stringify(jsonData)
//         });
//         if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//         }
//         return response;
//     }
// };
// // Teams fetch users API

// export const fetchExistingTeamsUsers = async (adminUserEmailId: string, token: string | null) => {
//     const jsonData = {
//         UserEmailId: adminUserEmailId,
//     };

//     const teamsResponse = await fetch(`${APIURL}/api/msteams/azure-ad-users`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify(jsonData),
//     });

//     if (!teamsResponse.ok) {
//         throw new Error(`HTTP error! status: ${teamsResponse.status}`);
//     }

//     return teamsResponse;
// };
// export const teamUsersToDB = async (loggedInUserId: string, adminUserEmail: string, token: string | null) => {

//     const requestData = {
//         UserEmailId: loggedInUserId,
//         AdminUserEmailId: adminUserEmail,
//     };
//     const response = await fetch(`${APIURL}/api/msteams/fetch-m365-users`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify(requestData)
//     });
//     if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//     }

//     return response;
// };
// export const fetchNewTeamsUsers = async (code: string, redirectUri: string, adminUserEmail: string, token: string | null) => {
//     const jsonData = {
//         code: code,
//         RedirectUri: redirectUri,
//         AdminUserEmailId: adminUserEmail,
//         IsNewUserLogin: true
//     };
//     const teamsResponse = await fetch(`${APIURL}/api/msteams/azure-ad-users`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify(jsonData),
//     });

//     if (!teamsResponse.ok) {
//         throw new Error(`HTTP error! status: ${teamsResponse.status}`);
//     }

//     return teamsResponse;
// };




// export const generateRefreshToken = async (token: string | null) => {
//     const jsonRequest = {
//         AccessToken: token,
//         RefreshToken: ""
//     };

//     const refreshTokenResponse = await fetch(`${APIURL}/api/authentication/refresh-token`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`
//         },
//         body: JSON.stringify(jsonRequest)
//     });
//     if (!refreshTokenResponse.ok) {
//         throw new Error(`HTTP error! status: ${refreshTokenResponse.status}`);
//     }
//     return refreshTokenResponse;
// };

// Slack users api
// export const getSlackUsers = async (token: string | null, code: string, adminUserEmail: string) => {
//     const jsonData = {
//         code: code,
//         AdminUserEmailId: adminUserEmail,
//     };
//     const response = await fetch(`${APIURL}/api/slacks/slack-users`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`
//         },
//         body: JSON.stringify(jsonData),
//     });
//     if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//     }
//     return response;
// };
