// src/constants.js or src/constants.ts

// **Authentication Constants**
export enum AUTH {
    ADMINLOGIN='account Login',
    SUCCESS= 'Authentication Success',
    FAILED= 'Authentication failed',
    NOTLICENSED = 'Not Licensed User',
    TEMPUSER='temp user',
    BLOB='Has Blob Storage',
    TEMP_USER_CONFIG='temporary user configuration'
};
export enum EMAIL{
    LOGIN = 'Email Login',
    SUCCESS= 'Authentication Success',
    FAILED= 'Authentication failed',
    REJECT = 'Rejected',
    ERROR = 'Error'
}

export enum SLACKAUTH {
    SLACKLOGIN='slack Login',
    SUCCESS= 'login Success',
    TEAMSLOGIN= 'Teams Login',
    FAILED= 'login Failed',
    TEAMSSUCCESS = 'Teams Login Success'
};
export const filterOptions = [
    { label: "Not Authenticated", value: "NotAuthenticated" },
    { label: "Approval Pending", value: "ApprovalPending" },
    { label: "Authenticated", value: "Authenticated" },
    { label: "Rejected", value: "Rejected" },
    { label: "Revoked", value: "Revoked" },
    { label: "Licensed", value: "Licensed" },
    { label: "Migrated", value: "Migrated" },
  ];

  export enum ROLES {
    ADMIN='Admin',
    TEMP_USER= 'TempUser',
    USER= 'User',
    AZURE_RM='AzureRM'
};
export enum STATUS{
    NotAuthenticated = 0 ,
    ApprovalPending = 1,
    Authenticated = 2,
    Rejected = 3,
    Revoked = 4
}
export enum ACTIONS{
    ADD_LICENSE='Add License',
    REMOVE_LICENSE= 'Remove License',
    REVOKE= 'Revoke',
    AUTHENTICATE= 'Authenticate',
}
export const STATUSORDER = [
    'ApprovalPending',
    'Authenticated',
    'NotAuthenticated',
    'Rejected',
    'Revoked',
  ];
  export const BATCH=50;