import Footer from "../../common/footer/footer";
import Header from "../../common/header/header";
import Metrics from "../../common/metrics/metrics";
import SlackLoginNew from "../dashboard/slack/slacklogin";
import TeamsLogin from "./teams/teamslogin";
import './dashboard.css';
import { GlobalStateContext } from "../../common/GlobalState";
import { useContext, useEffect, useState } from "react";
import { AUTH, SLACKAUTH } from "../../common/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSessionItem, removeSessionItem } from "../../common/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { ConfirmationModal } from "../confirmationModel/confirmatiommodel";
import { checkTokenExpiration } from "../../common/helper";
import { SLACKACCOUNT } from "../../common/ApiConfig";

const DashBoard: React.FC = () => {
    const { isLogout, setIsLogout } = useContext(GlobalStateContext);
    // const[ currentStep, setCurrentStep] =useState(SLACKAUTH.SLACKLOGIN);
    const { currentStep, setCurrentStep } = useContext(GlobalStateContext);
    const [searchParams] = useSearchParams();
    const [userName,setuserName] = useState<string>('')
    const [isModalOpen, setIsModalOpen] = useState(false);


    useEffect(() =>{
        const LoginUser = getSessionItem('SlackLoginData');
        try {
            const parseData = LoginUser ? JSON.parse(LoginUser) : null;
            if (parseData) {
                if (!Array.isArray(parseData) && typeof parseData === "object") {
                    setuserName(parseData.userName);
                } else {
                    setuserName(parseData[0]?.userName); 
                }
            }
        } catch (error) {
            setuserName('Guest');
        }
   },[currentStep])

    useEffect(() => {        
        const adminData = getSessionItem('adminData');
        const slackLoginData = getSessionItem('SlackLoginData');
        const teamLoginData = getSessionItem('TeamLoginData');
        if (teamLoginData) {
            navigate('/dashboardchannel');
        } 
        else if (slackLoginData) {
            setCurrentStep(SLACKAUTH.TEAMSLOGIN)
            navigate('/dashboard');
        }
        else if (adminData) {
            setCurrentStep(SLACKAUTH.SLACKLOGIN)
            navigate('/dashboard');
        }
        else {
            navigate('/');
        }
    }, [searchParams]);

    const navigate = useNavigate();
    useEffect(() => {
        setIsLogout(false)
    }, [])

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const confirmAction = () => {
        removeSessionItem('SlackLoginData');
        setCurrentStep(SLACKAUTH.SLACKLOGIN);
        navigate('/dashboard')
        setIsModalOpen(false);
    }
    const removeAdminData = () => {
        sessionStorage.clear();
        navigate('/');
    }

    useEffect(() => {
        const bearerToken = getSessionItem('bearerToken')
        if (bearerToken) {
            const token = bearerToken;
            const istokenValid = checkTokenExpiration(token)
            if (!istokenValid) {
                removeAdminData()
            }
        }
    })
    

    return (
        <>
            {isLogout && <div className="dashboard" />}
            <Header />
            <Metrics />
            <div className={` teams-and-slack ${currentStep === SLACKAUTH.SLACKLOGIN ? ' inactive' : 'active'}`}>
                {currentStep === SLACKAUTH.TEAMSLOGIN &&
                    <>
                        <div className='slack-title'>Slack</div>
                        <div className='slack-logged'>
                            <label className='slack-login-name' title={userName}>{userName.length>18 ?userName.slice(0,18)+'...':userName}</label>
                            <span>Loggedin</span>
                            <FontAwesomeIcon icon={faRightFromBracket} onClick={() => { setIsModalOpen(true) }} style={{ cursor: "pointer",marginLeft:"0.7rem" }} />
                        </div>
                    </>
                }
                <ConfirmationModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    onConfirm={confirmAction}
                    message={'Clicking on "Proceed" will log you out from slack account.'}
                />
                <div className="teams-slack">
                    <SlackLoginNew />
                    <TeamsLogin />
                </div>
            </div>

            <Footer />
        </>
    );
}
export default DashBoard;
