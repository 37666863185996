import eclipse from '../../assets/eclipse.png';
import slack from '../../assets/slack.png';
import to from '../../assets/arrow-1.png';
import teams from '../../assets/teams.png';
import saketa from "../../assets/saketa.png";
import failed from "../../assets/failed.gif";
import '../dashboardchannel/slackauthentication/slackauthentication.css';

const Authentication: React.FC = () => {

    return (
        <>
            <div className="s2t-main-screen">
                <div className="left-section">
                    <img src={eclipse} alt="eclipses" className="eclipse-left" />
                    <div className='slacktoteamsimage'>
                        <div><img src={slack} className='slackimage' alt='slackImage'/></div>
                        <div> <img src={to}  className='toimage' alt='ToImage'/></div>
                        <div> <img src={teams} className='teamsimage' alt='teamsImage'/></div>
                    </div>
                    <span className='contenttext'><center>Slack to Teams</center></span>
                    <div className='content'>
                        <span className='content-1'>Simplify transition from Slack to Microsoft Teams with "Saketa Secure Authentication" app, a companion for Saketa Migrator.</span>
                        <span className='content-2'>This app will allow admins to migrate users from Slack to Teams seamlessly without the hassle of manual login requests.</span>
                    </div>
                    <img src={eclipse} alt="eclipses" className="eclipse-right" />
                </div>
                <div className="right-section">
                    <div className="loginheader">
                        <img src={saketa} alt="saketa" className="saketalogo" />
                        <span className='saketaheader'>Saketa</span>
                    </div>
                    <div className="login-failed">
                        {/* <span>{error !== '' ? <div className="slack-error-msg">{error}</div> : ''}</span> */}
                        <div className="authfail"> Authentication Failed <img src={failed} alt="Failed" className="failedgif" /></div>
                        <div className="note">
                            <span>
                                Link is either expired or invalid
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Authentication;

