import '../ConfirmPopUP/confirmPopUp.css'

interface ConfirmationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    message: string;
    messageStyle?: React.CSSProperties;
}
export const ConfirmPopUp:React.FC<ConfirmationModalProps>=({ isOpen, onClose, onConfirm, message,messageStyle })=>{
    if (!isOpen) return null;
    return (
        <div className="confirmation">
            <div className="logout-confirm">
                <p className="logout-text" style={messageStyle}>{message}</p>
                {message.includes("successfully") || message.includes("Successfully") ||  message.includes("Please") || message.includes("exceed")
                ?
                <button onClick={onConfirm} className="proceed">OK</button>
                :
                <div className="buttons">
                    <button onClick={onClose} className="cancel">Cancel</button>
                    <button onClick={onConfirm} className="proceed">OK</button>
                </div>
                }

            </div>
        </div>
    );
}

