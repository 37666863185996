import { ChangeEvent, RefObject, useContext, useEffect, useRef, useState } from "react";
import 'bootstrap-icons/font/bootstrap-icons.css';
import slacklogo from '../../assets/slack.png';
import plus from '../../assets/adduser.png';
import slackDisable from '../../assets/slackdisable.png';
import teamslogo from '../../assets/teams.png';
import success from '../../assets/success.png';
import arrowLogo from '../../assets/arrow.png';
import teamsDisable from '../../assets/teamsdisable.png';
import Header from "../../common/header/header";
import '../dashboardchannel/dashboardchannel.css';
import { faArrowRight, faCircle, faHistory, faTimes, faEllipsisVertical, faCaretDown, faFolder, faXmark, faRightFromBracket, faCircleInfo, faFileImport, faFileExport, faDownload, faKey, faRotateRight, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import close from '../../assets/close.png';
import downarrow from '../../assets/downarrow.png';
import adminmetrics from '../../assets/3Userdashboard.png'
import workspaceicon from '../../assets/wrkspaceicon.png';
import { GlobalStateContext } from "../../common/GlobalState";
import { assignLicenses, fetchActivityInfo, fetchAddSaketaSlackUsers, fetchAddSaketaTeamUsers, fetchAddSlackUsers, fetchAddUsers, fetchSlackUsers, fetchTeamUsers, getDefaultSlackUsers, getDefaultTeamUsers, removeLicenses, revokeSlackRequest, revokeTeamsRequest, sendEmailsForSlack, sendEmailsForTeams } from "../../common/authServices";
import { getInitials, getSessionItem, removeSessionItem, checkTokenExpiration, setSessionItem } from "../../common/helper";
import dummyimage from '../../assets/dummy-image.png';
import searchicon from '../../assets/search-icon.png';
import filtericon from '../../assets/filter-icon.png';
import ReactPaginate from "react-paginate";
import { ACTIONS, BATCH, filterOptions, ROLES, STATUS, STATUSORDER } from "../../common/constants";
import { CheckedUser, CheckedUsers } from "../../common/interfaces";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ConfirmationModal } from "../confirmationModel/confirmatiommodel";
import { ConfirmPopUp } from "../ConfirmPopUP/confirmPopUp"
import { TbArrowsSort } from "react-icons/tb";
import { TeamUsers } from '../../common/interfaces';
import * as XLSX from 'xlsx';
import spinnerGif from "../../assets/saketa-loading.gif";
import { templateData } from "../../common/downloadTeamplate";
import moment from 'moment';
import React from "react";
// import { slackUser } from "../../common/interfaces";
// import album from '../../assets/album.png';
// import AddUserIcon from '../../assets/user.png';


const DashBoardChannel: React.FC = () => {
    const [teamState, setTeamState] = useState(false);
    const [slackState, setSlackState] = useState(true);
    const [loggedInuserName, setLoggedInuserName] = useState('');
    const [workspaceName, setWorkspaceName] = useState('');
    const [domainName, setDomainName] = useState('');
    const [hoverLoggedInuserName, setHoverLoggedInuserName] = useState('');
    const [hoverWorkspaceName, setHoverWorkspaceName] = useState('');
    const [hoverdomainName, setHoverDomainName] = useState('');
    const [addUserstate, setAddUserState] = useState(false);
    const [showFlyOut, setShowFlyOut] = useState(false);
    const [action, setAction] = useState<string>('');
    const [usermetrics, setUserMetrics] = useState(false);
    const [wrkspacemetrics, setwrkspace] = useState(false);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [checkedUsers, setCheckedUsers] = useState<CheckedUsers>({});
    const [checkedUsersAdd, setCheckedUsersAdd] = useState<CheckedUser[]>([]);
    const [selectedCount, setSelectedCount] = useState(0);
    const [mrgmetrics, setmrgmetrics] = useState(false);
    const [activityData, setActivityData] = useState([]);
    const [isError, setIsError] = useState<boolean>(false);
    const [finalFilteredUsers, setFinalFilteredUsers] = useState<TeamUsers[]>([]);
    const [addFinalFilteredUsers, setAddFinalFilteredUsers] = useState<TeamUsers[]>([]);
    const [finalPaginatedUsers, setFinalPaginatedUsers] = useState<TeamUsers[]>([]);
    const [finalAddPaginatedUsers, setFinalAddPaginatedUsers] = useState<TeamUsers[]>([]);
    const [addTeamUsers, setAddTeamUsers] = useState<TeamUsers[]>([]);
    const [finalUsers, setFinalUsers] = useState<TeamUsers[]>([]);
    const [dashBoardUsers, setDashBoardUsers] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [showImportPopUp, setShowImportPopUp] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [addPageCount, setAddPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [addCurrentPage, setAddCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [addPageSize, setAddPageSize] = useState(10);
    const [workSpaceUsers, setWorkSpaceUsers] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [addSearchTerm, setAddSearchTerm] = useState<string>('');
    const [sortTerm, setSortTerm] = useState('');
    const [showFilterDropDown, setShowFilterDropDown] = useState(false);
    const [menuDropDown, setMenuDropDown] = useState(false);
    const [loggedInDropdown, setLoggedInDropdown] = useState(false);
    const [showBulkActionDropDown, setShowBulkActionDropDown] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const pageSizeOptions = [5, 10, 15, 20, 50, 100];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isResultModal, setIsResultModal] = useState(false);
    const [navModel, setNavModel] = useState(false);
    const [selectedUser, setSelectedUser] = useState<any[] | null>(null);
    const [modalMessage, setModalMessage] = useState('');
    const [importError, setImportError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [externalLicense, setExternalLicense] = useState<any | null>();
    const [workspaceMatrix, setWorkspaceMatrix] = useState<any | null>();
    const [sortConfig, setSortConfig] = useState<{ key: keyof any | null, direction: 'asc' | 'desc' }>({
        key: null,
        direction: 'asc'
    });
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [userSwitchTrigger, setUserSwitchTrigger] = useState(false);
    const [totalUsers, setTotalUsers] = useState<number>(0);
    const [authenticatedUsers, setAuthenticatedUsers] = useState<number>(0);
    const [notAuthenticatedUsers, setNotAuthenticatedUsers] = useState<number>(0);
    const [licenseUsers, setlicenseUsers] = useState<number>(0);
    const [migratedUsers, setMigratedUsers] = useState<number>(0);
    const [error, setError] = useState<any>('')
    const [actionDropDown, setActionDropDown] = useState<any>(false);
    const [selectedItems, setSelectedItems] = useState<{ [key: string]: string }>({});
    const colors = ["#001F3F", "#228B22", "#8B0000", "#4B0082", "#008080", "#FF4500", "#B8860B"];
    const fileInputRef = React.useRef<HTMLInputElement | null>(null);


    useEffect(() => {

        if (teamState) {
            TeamDefaultUsers();
        }
        else {
            slackDefaultUsers()
        }

    }, [])
    const handleRefresh = () => {
        if (!isError) {
            if (teamState) {
                TeamDefaultUsers();
            }
            else {
                slackDefaultUsers()
            }
        }
        handleClearAll()
    }

    useEffect(() => {
        setFinalPaginatedUsers(finalUsers)
    }, [finalUsers])
    useEffect(() => {
        setFinalAddPaginatedUsers(addTeamUsers)
    }, [addTeamUsers])
    useEffect(() => {
        setSelectedFilters([])
    }, [teamState, slackState])

    useEffect(() => {
        const filteredUsers = finalUsers.filter((user: TeamUsers) => {
            const userName = user.userName || '';
            const userEmail = user.email || '';
            return (
                userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                userEmail.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
        const finallyFilteredUsers = selectedFilters.length > 0
            ? (() => {
                if (selectedFilters[0] === 'Licensed') {
                    const filtered = filteredUsers.filter((user: TeamUsers) =>
                        user?.isLicensed === true
                    );
                    return filtered;
                }
                else if (selectedFilters[0] === 'Migrated') {
                    const filtered = filteredUsers.filter((user: TeamUsers) =>
                        user?.isMigrated === true
                    );
                    return filtered;
                }
                else {
                    const selectedFiltersNumeric = selectedFilters.map(
                        (filter) => STATUS[filter as keyof typeof STATUS]
                    );
                    const filtered = filteredUsers.filter((user: TeamUsers) =>
                        selectedFiltersNumeric.includes(user.status)
                    );
                    return filtered;
                }

            })()
            : filteredUsers;
        if (sortTerm !== '') {
            const key = sortTerm as keyof TeamUsers;

            if (key) {
                const sortedData = finallyFilteredUsers.sort((a: TeamUsers, b: TeamUsers) => {
                    const aValue = a[key];
                    const bValue = b[key];

                    if (typeof aValue === 'number' && typeof bValue === 'number') {
                        const aStatus = STATUS[aValue];
                        const bStatus = STATUS[bValue];

                        const aIndex = STATUSORDER.indexOf(aStatus);
                        const bIndex = STATUSORDER.indexOf(bStatus);

                        return (aIndex - bIndex) * (sortConfig.direction === 'asc' ? 1 : -1);
                    }

                    const aStringValue = aValue ? aValue.toString().toLowerCase() : '';
                    const bStringValue = bValue ? bValue.toString().toLowerCase() : '';

                    if (aStringValue < bStringValue) {
                        return sortConfig.direction === 'asc' ? -1 : 1;
                    }
                    if (aStringValue > bStringValue) {
                        return sortConfig.direction === 'asc' ? 1 : -1;
                    }

                    return 0;
                });

                setFinalPaginatedUsers(sortedData);
                setCurrentPage(0);
            }
        }
        else {
            setFinalPaginatedUsers(finallyFilteredUsers);
            setCurrentPage(0);
        }


    }, [searchTerm, selectedFilters, sortTerm, sortConfig.direction]);
    const openResultModal = (message: string, isError: boolean) => {
        setIsResultModal(true);
        setIsError(isError);
        setModalMessage(message)
        setCheckedUsers({})
    }
    useEffect(() => {
        setIsAllChecked(false);
        setCheckedUsers({})
        setLoading(true);
        const startIdx = currentPage * pageSize;
        const endIdx = startIdx + pageSize;
        setFinalFilteredUsers(finalPaginatedUsers.slice(startIdx, endIdx));
        setPageCount(Math.max(Math.ceil(finalPaginatedUsers.length / pageSize)));
        setLoading(false);
    }, [pageSize, currentPage, finalPaginatedUsers]);
    const handleSortButtonClick = (key: keyof TeamUsers) => {
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortTerm(key);
        setSortConfig({ key, direction });
    };

    useEffect(() => {

        const filteredUsers = addTeamUsers.filter((user: TeamUsers) => {
            const userName = user.userName || '';
            const userEmail = user.email || '';
            return (
                userName.toLowerCase().includes(addSearchTerm.toLowerCase()) ||
                userEmail.toLowerCase().includes(addSearchTerm.toLowerCase())
            );
        });
        setFinalAddPaginatedUsers(filteredUsers);
        setAddCurrentPage(0);
    }, [addSearchTerm]);
    useEffect(() => {
        setLoading(true);
        const startIdx = addCurrentPage * addPageSize;
        const endIdx = startIdx + addPageSize;
        setAddFinalFilteredUsers(finalAddPaginatedUsers.slice(startIdx, endIdx));
        setAddPageCount(Math.max(Math.ceil(finalAddPaginatedUsers.length / addPageSize)));
        setLoading(false);
    }, [addPageSize, addCurrentPage, finalAddPaginatedUsers]);

    //condition check
    useEffect(() => {
        const adminData = getSessionItem('adminData');
        const teamLoginData = getSessionItem('TeamLoginData');
        if (teamLoginData) {
            navigate('/dashboardchannel');

        }
        else if (adminData) {
            navigate('/dashboard');
        }
        else {
            navigate('/');
        }
    }, [searchParams]);

    const closeModal = () => {
        setNavModel(false);
    };
    const toggleFlyout = async () => {
        await activityFeedInfo();
        setShowFlyOut(!showFlyOut);

    }

    const teamClicked = () => {
        TeamDefaultUsers();
        setTeamState(true);
        setSelectedItems({})
        setSlackState(false);
        setCheckedUsers({})
        setIsAllChecked(false);
        setSearchTerm('')
    }
    const handleCloseLog = () => {
        setAddUserState(false);
        setShowFlyOut(false);
        setIsAllChecked(false);
        setAddSearchTerm('')
        setCheckedUsers({})
        setCheckedUsersAdd([])
        setAddCurrentPage(0)
        // setSearchQuery('');
    }

    const slackClicked = () => {
        slackDefaultUsers();
        setSelectedItems({})
        setCheckedUsers({})
        setIsAllChecked(false)
        setTeamState(false);
        setSlackState(true);
        setSearchTerm('')
    }

    const toggleDropdown = () => {
        setLoggedInDropdown(!loggedInDropdown);
    };
    const handleErrorOk = () => {
        setError('');
        console.log(error.message, "errror message check")
    }
    const handleCloseSearch = () => {
        setSearchTerm('')
    }
    const handleFetchAllUsers = async () => {
        setIsLoading(true);
        const bearerToken = getSessionItem('bearerToken')
        const adminData = getSessionItem('adminData');
        const parsedata = JSON.parse(adminData);
        const teamLoginData = getSessionItem('TeamLoginData');
        const teamData = JSON.parse(teamLoginData);
        try {
            if (bearerToken !== '') {
                const token = bearerToken;
                const istokenValid = checkTokenExpiration(token);
                if (istokenValid) {
                    const users = await fetchTeamUsers(token, parsedata, teamData);
                    const responseData = await users.json();
                    if (responseData?.data.length !== 0) {
                        TeamDefaultUsers()
                    }
                    // const teamUsers: TeamUsers[] = responseData.data;
                    // setFinalUsers(teamUsers);
                    // setFinalPaginatedUsers(finalUsers)
                    setIsLoading(false);
                }
            }
        }
        catch (error) {
            setError(error)
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }

    }
    const handleFetchAllSlackUsers = async () => {
        setIsLoading(true);
        const bearerToken = getSessionItem('bearerToken')
        const slackLoginData = getSessionItem('SlackLoginData')
        const parsedata = JSON.parse(slackLoginData);
        try {
            if (bearerToken !== '') {
                const token = bearerToken;
                const istokenValid = checkTokenExpiration(token);
                if (istokenValid) {
                    const users = await fetchSlackUsers(token, parsedata);
                    const responseData = await users.json();
                    if (responseData?.data.length !== 0) {
                        slackDefaultUsers()
                    }
                    // const teamUsers: TeamUsers[] = responseData.data;
                    // setFinalUsers(teamUsers);
                    // setFinalPaginatedUsers(finalUsers)
                    setIsLoading(false);
                }
            }
        }
        catch (error) {
            setError(error)
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }

    }
    const TeamDefaultUsers = async () => {
        setIsLoading(true);
        const bearerToken = getSessionItem('bearerToken')
        const adminData = getSessionItem('adminData')
        const parsedata = JSON.parse(adminData);
        const teamLoginData = getSessionItem('TeamLoginData');
        const teamData = JSON.parse(teamLoginData);
        try {
            if (bearerToken !== '') {
                const token = bearerToken;
                const istokenValid = checkTokenExpiration(token);
                if (istokenValid) {
                    const users = await getDefaultTeamUsers(token, parsedata, teamData);
                    const responseData = await users.json();
                    const teamUsers: TeamUsers[] = responseData.data;
                    const counts = teamUsers.reduce(
                        (acc: any, user: any) => {
                            if (user.status === 1) acc.notAuthenticated++;
                            if (user.status === 2) acc.authenticated++;
                            return acc;
                        },
                        { notAuthenticated: 0, authenticated: 0 }
                    );
                    setTotalUsers(teamUsers.length);
                    setAuthenticatedUsers(counts.authenticated)
                    setNotAuthenticatedUsers(counts.notAuthenticated)
                    setFinalUsers(teamUsers);
                    setIsLoading(false);
                }
            }
        }
        catch (error) {
            setError(error)
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }

    }
    const slackDefaultUsers = async () => {
        setIsLoading(true);
        const bearerToken = getSessionItem('bearerToken')
        const slackData = getSessionItem('SlackLoginData');
        const parsedata = JSON.parse(slackData);
        try {
            if (bearerToken !== '') {
                const token = bearerToken;
                const istokenValid = checkTokenExpiration(token);
                if (istokenValid) {
                    const users = await getDefaultSlackUsers(token, parsedata);
                    const responseData = await users.json();
                    setExternalLicense(responseData.data.externalLicenses);
                    const slackUsers: TeamUsers[] = responseData.data.slackUsers;
                    const counts = slackUsers.reduce(
                        (acc: any, user: any) => {
                            if (user.status === 1) acc.notAuthenticated++;
                            if (user.status === 2) acc.authenticated++;
                            if (user.isLicensed) acc.licensedCount++;
                            if (user.isMigrated) acc.migratedCount++;
                            return acc;
                        },
                        {
                            notAuthenticated: 0,
                            authenticated: 0,
                            licensedCount: 0,
                            migratedCount: 0
                        }
                    );

                    setTotalUsers(slackUsers.length);
                    setAuthenticatedUsers(counts.authenticated);
                    setNotAuthenticatedUsers(counts.notAuthenticated);
                    setlicenseUsers(counts.licensedCount);
                    setMigratedUsers(counts.migratedCount);
                    setFinalUsers(slackUsers);
                    setIsLoading(false);
                }
            }
        }
        catch (error) {
            setError(error)
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }

    }

    const handleAddUserPageClick = (data: any) => {
        setAddCurrentPage(data.selected);
    };
    const handleAddUserPageSizeChange = (event: any) => {
        setAddCurrentPage(0);
        setAddPageSize(Number(event.target.value));
    };

    const handleSlackUser = () => {
        removeSessionItem('SlackLoginData');
        removeSessionItem('TeamLoginData');
        removeSessionItem('SlackLoginData');
        navigate('/dashboard')

    }
    const handleAddTeamsUsers = async () => {
        setIsLoading(true)
        const bearerToken = getSessionItem('bearerToken')
        const teamLoginData = getSessionItem('TeamLoginData');
        const teamData = JSON.parse(teamLoginData);

        try {
            if (bearerToken !== '') {
                const token = bearerToken;
                const istokenValid = checkTokenExpiration(token);
                if (istokenValid) {
                    const users = await fetchAddUsers(token, teamData);
                    const responseData = await users.json();
                    const teamUsers: TeamUsers[] = responseData.data;
                    setAddTeamUsers(teamUsers);
                    setFinalAddPaginatedUsers(teamUsers);
                    setIsLoading(false)
                    setAddUserState(true)
                }
            }
        }
        catch (error) {
            setError(error)
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    }
    const handleAddSlacksUsers = async () => {
        setIsLoading(true)
        const bearerToken = getSessionItem('bearerToken')
        const slackLoginData = getSessionItem('SlackLoginData')
        const parsedata = JSON.parse(slackLoginData);
        try {
            if (bearerToken !== '') {
                const token = bearerToken;
                const istokenValid = checkTokenExpiration(token);
                if (istokenValid) {
                    const users = await fetchAddSlackUsers(parsedata, token);
                    const responseData = await users.json();
                    const teamUsers: TeamUsers[] = responseData.data;
                    setAddTeamUsers(teamUsers)
                    setIsLoading(false)
                    setAddUserState(true)
                }
            }
        }
        catch (error) {
            setError(error)
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    }
    const handleEmailsForSlack = async () => {
        setIsLoading(true)
        const bearerToken = getSessionItem('bearerToken');
        const slackLoginData = getSessionItem('SlackLoginData');
        const slackData = JSON.parse(slackLoginData);
        try {
            if (bearerToken !== '') {
                const token = bearerToken;
                const istokenValid = checkTokenExpiration(token);
                if (istokenValid && selectedUser) {
                    if (selectedUser.length > BATCH) {
                        const batchSize = BATCH;
                        const batches = [];
                        for (let i = 0; i < selectedUser.length; i += batchSize) {
                            const batch = selectedUser.slice(i, i + batchSize).map(user => ({
                                userId: user.userID,
                            }))
                            batches.push(batch);
                        }
                        const responses = await Promise.all(
                            batches.map(async (batch) => {
                                const responseData = await sendEmailsForSlack(token, slackData, batch);
                                return responseData;
                            })
                        );

                        openResultModal('Email successfully sent to the slack', false)
                    }
                    else if (selectedUser.length <= BATCH) {
                        const userIdArray = selectedUser.map(user => ({ userId: user.userID }))
                        const users = await sendEmailsForSlack(token, slackData, userIdArray);
                        const responseData = await users.json();
                        openResultModal('Email successfully sent to the slack', false)
                    }
                }
            }
        }
        catch (error: any) {
            openResultModal(error?.message, true)
            setIsLoading(false);
        }
        finally {
            handleClearAll();
            setIsLoading(false);
        }
    }
    const handleEmailsForTeams = async () => {
        setIsLoading(true)
        const bearerToken = getSessionItem('bearerToken');
        const teamLoginData = getSessionItem('TeamLoginData');
        const teamData = JSON.parse(teamLoginData);
        try {
            if (bearerToken !== '') {
                const token = bearerToken;
                const istokenValid = checkTokenExpiration(token);
                if (istokenValid && selectedUser) {
                    if (selectedUser.length > BATCH) {
                        const batchSize = BATCH;
                        const batches = [];
                        for (let i = 0; i < selectedUser.length; i += batchSize) {
                            const batch = selectedUser.slice(i, i + batchSize).map(user => ({
                                userId: user.userID,
                            }))
                            batches.push(batch);
                        }
                        const responses = await Promise.all(
                            batches.map(async (batch) => {
                                const responseData = await sendEmailsForTeams(token, teamData, batch);
                                return responseData;
                            })
                        );
                        openResultModal('Email successfully sent to the slack', false)
                    }
                    else if (selectedUser.length <= BATCH) {
                        const userIdArray = selectedUser.map(user => ({ userId: user.userID }))
                        const users = await sendEmailsForTeams(token, teamData, userIdArray);
                        const responseData = await users.json();
                        openResultModal('Email successfully sent to the teams', false)
                    }
                }
            }
        }
        catch (error: any) {
            openResultModal(error?.message, true)
            setIsLoading(false);
        }
        finally {
            handleClearAll();
            setIsLoading(false);
        }
    }
    const handleRevokeTeamsRequest = async () => {
        setIsLoading(true)

        const bearerToken = getSessionItem('bearerToken');
        const loginData = getSessionItem('TeamLoginData');
        const Data = JSON.parse(loginData);
        try {
            if (bearerToken !== '') {
                const token = bearerToken;
                const istokenValid = checkTokenExpiration(token);
                if (istokenValid && selectedUser) {
                    const licensedUsers = selectedUser.map(user => user.userID);
                    const users = await revokeTeamsRequest(token, Data, licensedUsers);
                    const responseData = await users.json();
                    if (licensedUsers.length === 1) {
                        openResultModal('Team user has been successfully revoked.', false)
                    }
                    else {
                        openResultModal('Team users have been successfully revoked.', false)
                    }
                }
            }
        }
        catch (error: any) {
            openResultModal(error?.message, false)
            setIsLoading(false);
        }
        finally {
            handleClearAll();
            setIsLoading(false);
        }
    }
    const handleRevokeSlackRequest = async () => {
        setIsLoading(true)
        var loginData;
        var Data;
        const bearerToken = getSessionItem('bearerToken');
        loginData = getSessionItem('SlackLoginData');
        Data = JSON.parse(loginData);
        try {
            if (bearerToken !== '') {
                const token = bearerToken;
                const istokenValid = checkTokenExpiration(token);
                if (istokenValid && selectedUser) {
                    const licensedUsers = selectedUser.map(user => user.userID);
                    const users = await revokeSlackRequest(token, Data, licensedUsers);
                    const responseData = await users.json();
                    if (licensedUsers.length === 1) {
                        openResultModal('Slack user has been successfully revoked.', false)
                    }
                    else {
                        openResultModal('Slack users have been successfully revoked.', false)
                    }


                }
            }
        }
        catch (error: any) {
            openResultModal(error?.message, true)
            setIsLoading(false);
        }
        finally {
            handleClearAll();
            setIsLoading(false);
        }
    }
    const handleRemoveLicense = async () => {
        setIsLoading(true)
        const bearerToken = getSessionItem('bearerToken');
        const slackLoginData = getSessionItem('SlackLoginData');
        const slackData = JSON.parse(slackLoginData);
        try {
            if (bearerToken !== '') {
                const token = bearerToken;
                const istokenValid = checkTokenExpiration(token);
                if (istokenValid && selectedUser) {
                    const userIdArray = selectedUser.map(user => user.userID)
                    const users = await removeLicenses(token, slackData, userIdArray);
                    const responseData = await users.json();
                    openResultModal('Successfully removed the licenses', false)
                }
            }
        }
        catch (error: any) {
            openResultModal(error?.message, true)
            setIsLoading(false);
        }
        finally {
            handleClearAll();
            setIsLoading(false);
        }
    }
    const handleAssignLicense = async () => {
        setIsLoading(true)
        const bearerToken = getSessionItem('bearerToken');
        const slackLoginData = getSessionItem('SlackLoginData');
        const slackData = JSON.parse(slackLoginData);
        try {
            if (bearerToken !== '') {
                const token = bearerToken;
                const istokenValid = checkTokenExpiration(token);
                if (istokenValid && selectedUser) {
                    const userIdArray = selectedUser.map(user => user.userID)
                    const users = await assignLicenses(token, slackData, userIdArray);
                    const responseData = await users.json();
                   
                    if (userIdArray.length === 1)
                        openResultModal('License has been successfully added.', false)
                    else {
                        openResultModal('Licenses have been successfully added.', false)
                    }
                }
            }
        }
        catch (error: any) {
            openResultModal(error?.message, true)
            setIsLoading(false);
        }
        finally {
            handleClearAll();
            setIsLoading(false);
        }
    }

    const handlePageClick = (data: any) => {
        setCurrentPage(data.selected);
    };
    const handlePageSizeChange = (event: any) => {
        setCurrentPage(0);
        setPageSize(Number(event.target.value));
    };
    const popupRef: RefObject<HTMLDivElement> = useRef(null);
    const handleClickOutside = (event: MouseEvent) => {
        if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
            setAddUserState(false);
        }
    };

    const handleTeamUser = () => {
        removeSessionItem('TeamLoginData');
        navigate('/dashboard');
    }
    const handleAddIcon = async (user: any[],flag:string) => {
        if (teamState) {
            setIsLoading(true)
            const bearerToken = getSessionItem('bearerToken')
            const teamLoginData = getSessionItem('TeamLoginData')
            const parsedata = JSON.parse(teamLoginData);

            try {
                if (bearerToken !== '') {
                    const token = bearerToken;
                    const istokenValid = checkTokenExpiration(token);
                    if (istokenValid) {

                        const users = await fetchAddSaketaTeamUsers(token, parsedata, user);
                        const responseData = await users.json();
                        const result = responseData.data.nonTeamsUsers;
                        const nonTeamsUsers = result.map((user: { userName: any; email: any; }) => ({
                            userName: user.userName,
                            email: user.email
                        }));
                        if (responseData) {
                            TeamDefaultUsers()
                        }
                        setIsLoading(false)
                        return nonTeamsUsers;

                    }
                }
            }
            catch (error) {
                if(flag!=="import") { 
                    setError(error)
                }
                setIsLoading(false);
            }
            finally {

                setIsLoading(false);
            }

        }
        else {
            setIsLoading(true)
            const bearerToken = getSessionItem('bearerToken')
            const slackLoginData = getSessionItem('SlackLoginData')
            const parsedata = JSON.parse(slackLoginData);
            try {
                if (bearerToken !== '') {
                    const token = bearerToken;
                    const istokenValid = checkTokenExpiration(token);
                    if (istokenValid) {
                        const users = await fetchAddSaketaSlackUsers(token, parsedata, user);
                        const responseData = await users.json();
                        const result = responseData.data.nonSlackUsers;
                        if (responseData) {
                            setAddUserState(false)
                            slackDefaultUsers();
                        }
                        setIsLoading(false);
                        return result;
                    }
                }
            }
            catch (error) {
                setError(error);
                setIsLoading(false);
            }
            finally {

                setIsLoading(false);
            }

        }
    }
    const handleImport = () => {
        setImportError("");
        setSelectedFile(null);
        setShowImportPopUp(true);
        setMenuDropDown(false);
    }
    const handleClearAll = () => {
        setSelectedFilters([])
        setSearchTerm('')
        setCheckedUsers({})
        setIsAllChecked(false)
    }
    useEffect(() => {
        const SlackLoginUser = getSessionItem('SlackLoginData');
        const teamsLoginUser = getSessionItem('TeamLoginData');
        const lMetrics = getSessionItem('lMetrics');
        const metricsParseData = lMetrics && JSON.parse(lMetrics);
        const LoginUser = slackState ? SlackLoginUser : teamsLoginUser;
        try {
            const parseData = LoginUser ? JSON.parse(LoginUser) : '';
            if (parseData !== '' && parseData.userName) {
                const slackWorkSpaceName = slackState ? parseData.workspaceName : '';
                setWorkspaceMatrix(metricsParseData);
                setHoverWorkspaceName(slackWorkSpaceName);
                setHoverLoggedInuserName(parseData.userName);
                const DomainName = handleDomainName(parseData.email);
                setHoverDomainName(DomainName);
                const truncatedUserName = parseData.userName.length > 10 ? `${parseData.userName.slice(0, 10)}...` : parseData.userName;
                const truncatedDomainName = DomainName.length > 13 ? `${DomainName.slice(0, 13)}...` : DomainName;
                const truncatedWrkspc = slackWorkSpaceName.length > 13 ? `${slackWorkSpaceName.slice(0, 13)}...` : slackWorkSpaceName;
                setLoggedInuserName(truncatedUserName);
                setDomainName(truncatedDomainName);
                setWorkspaceName(truncatedWrkspc);
            }
        } catch (error) {
            setLoggedInuserName('Guest');
        }
    }, [slackState]);
    useEffect(() => {
        const SlackLoginUser = getSessionItem('SlackLoginData');
        const slackparseData = JSON.parse(SlackLoginUser);
        const slackWorkSpaceData = getSessionItem('slackWorkSpaceData');
        const parseData = JSON.parse(slackWorkSpaceData);

        const filteredUsers = parseData
            ? parseData.filter((user: { email: string; workspaceName: string; }) => {
                return (
                    user.email === slackparseData.email &&
                    user.workspaceName !== slackparseData.workspaceName
                );
            })
            : [];
        setWorkSpaceUsers(filteredUsers);
    }, [userSwitchTrigger]);
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const openModal = (user: any, message: string, action: string) => {
        const values = Object.values(user);
        const hasObjectValues = values.some(value => typeof value === "object" && typeof value !== "string" && value !== null);
        if (hasObjectValues) {
            setSelectedUser(values);
        } else {
            setSelectedUser([user]);
        }
        setShowBulkActionDropDown(false);
        setAction(action);
        setModalMessage(message);
        setIsModalOpen(true);
    };
    const closePopUP = () => {
        setIsModalOpen(false);
        setIsResultModal(false)
        setSelectedUser(null);
        setModalMessage('');
    };
    const okAction = () => {
        setIsResultModal(false);
        if (teamState) {
            TeamDefaultUsers();
        }
        else {
            slackDefaultUsers();
        }
    }
    const confirmAction = () => {
        setIsModalOpen(false);
        if (action == ACTIONS.ADD_LICENSE) {
            const filtered: any = selectedUser?.filter(item => item.status === 2 && item?.isMigrated === false && !item?.isLicensed);

            if (filtered.length === selectedUser?.length) {
                handleAssignLicense();
                handleClearAll()
            }
            else {

                openResultModal('Please select valid users to perform bulk actions.', true)
            }

        }
        else if (action == ACTIONS.REMOVE_LICENSE) {
            const filtered: any = selectedUser?.filter(item => item.isLicensed && item?.isMigrated === false);
            if (filtered.length === selectedUser?.length) {
                handleRemoveLicense();
                handleClearAll()
            }
            else {
                openResultModal('Please select valid users to perform bulk actions.', true)
            }
        }
        else if (action == ACTIONS.AUTHENTICATE) {

            const filteredStatuses = [0, 3, 4];
            const filtered: any = teamState ? selectedUser?.filter(item => filteredStatuses.includes(item.status)) : selectedUser?.filter(
                (item) => filteredStatuses.includes(item.status)
            );
            if (filtered.length === selectedUser?.length) {
                teamState ? handleEmailsForTeams() : handleEmailsForSlack();
                handleClearAll()
            }
            else {

                openResultModal('Please select valid users to perform bulk actions.', true)
            }
        }
        else if (action == ACTIONS.REVOKE) {
            const filtered: any = selectedUser?.filter(item => item.status === 2);
            if (filtered.length === selectedUser?.length) {
                teamState ? handleRevokeTeamsRequest() : handleRevokeSlackRequest();
                handleClearAll()
            }
            else {
                openResultModal('Please select valid users to perform bulk actions.', true)
            }

        }
        else {
            setModalMessage('');
        }
        // setSelectedUser(null);

    }
    const handleActionDropDown = (user: any, message: string, action: string) => {
        if (user.role !== ROLES.ADMIN) {
            if (teamState) {
                openModal(user, message, action)
            }
            else {
                setActionDropDown(user)
            }
        }
        else {
            if (slackState) {
                openModal(user, message, action)
            }
        }
    }
    const handleDropdownChange = (user: TeamUsers, event: any, message: string) => {
        const newSelectedItems = { ...selectedItems };
        newSelectedItems[user.userID] = event.target.value;
        setSelectedItems(newSelectedItems);
        if (event.target.value === ACTIONS.ADD_LICENSE) {
            openModal(user, "Are you sure you want to assign a license to this user?", ACTIONS.ADD_LICENSE)
            newSelectedItems[user.userID] = "Select";
        }
        else if (event.target.value === ACTIONS.REMOVE_LICENSE) {
            openModal(user, "Are you sure you want to remove the license from this user?", ACTIONS.REMOVE_LICENSE)
            newSelectedItems[user.userID] = "Select";
        }
        else {
            openModal(user, message, ACTIONS.REVOKE)
            newSelectedItems[user.userID] = "Select";
        }

    };
    const getStatusComponent = (user: TeamUsers, status: STATUS) => {
        let message = '';

        switch (status) {
            case STATUS.NotAuthenticated:
                message = 'Are you sure you want to authenticate this user?';
                return <button onClick={() => { openModal(user, message, ACTIONS.AUTHENTICATE); }} className={`action1-btn action-button`}><span>Authenticate</span> <FontAwesomeIcon icon={faArrowRight} /></button>;
            case STATUS.Authenticated:
                message = "Are you sure you want to revoke this user's authentication?";
                return (
                    <div>
                        <button className={`${user.role === ROLES.ADMIN ? 'revoke-admin' : teamState ? 'reauthenticate-btn' : 'reauthenticate-btn-select'}`}>
                            {teamState ? <div style={user.role === ROLES.ADMIN ? { cursor: 'not-allowed' } : { cursor: 'pointer' }} onClick={() => { handleActionDropDown(user, message, ACTIONS.REVOKE) }}>Revoke</div> : user.role === ROLES.ADMIN ? <div className="slack-admin" onClick={() => { user?.isLicensed ? handleActionDropDown(user, "Are you sure you want to remove license to the admin?", ACTIONS.REMOVE_LICENSE) : handleActionDropDown(user, "Are you sure you want to add license to the admin?", ACTIONS.ADD_LICENSE) }}>{user?.isLicensed ? ACTIONS.REMOVE_LICENSE : ACTIONS.ADD_LICENSE}</div> :
                                <select
                                    id="dropdown"
                                    value={selectedItems[user.userID] || "Select"}
                                    onChange={(event) => handleDropdownChange(user, event, message)}
                                    className="dropdown-select"
                                >
                                    <option value="Select" style={{ display: "none" }}>
                                        Select
                                    </option>
                                    {!user?.isMigrated && <option value={user?.isLicensed ? ACTIONS.REMOVE_LICENSE : ACTIONS.ADD_LICENSE} className="action-option">
                                        {user?.isLicensed ? ACTIONS.REMOVE_LICENSE : ACTIONS.ADD_LICENSE}
                                    </option>}
                                    <option value={ACTIONS.REVOKE} className="action-option" >
                                        Revoke
                                    </option>
                                </select>}
                        </button>
                    </div>)
            case STATUS.ApprovalPending:
                return <span className={`sent-btn`}>Requested <img src={success} alt="success" className="sent-mark" /> </span>;
            case STATUS.Revoked:
            case STATUS.Rejected:
                message = 'Are you sure you want to re-authenticate this user?';
                return (
                    <button onClick={() => { openModal(user, message, ACTIONS.AUTHENTICATE); }} className="action1-btn action-button">
                        Authenticate <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                );

        }
    }
    const getinfotext = (user: any, status: STATUS): string => {
        switch (status) {
            case STATUS.NotAuthenticated:
                return "User has not been authenticated."
            case STATUS.ApprovalPending:
                return "Access request awaiting approval.";
            case STATUS.Authenticated:
                return "User has been successfully authenticated.";
            case STATUS.Rejected:
                return "User's access has been rejected.";
            case STATUS.Revoked:
                return "User's access has been revoked.";
            default:
                return "No status available.";
        }
    };

    const handleOutsideClick = (event: any) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setShowFilterDropDown(false);
            setShowBulkActionDropDown(false);
            setMenuDropDown(false);
            setActionDropDown(false);
            setLoggedInDropdown(false);
        }

    };
    const handleDomainName = (email: string) => {
        const atIndex = email.indexOf('@');
        const dotIndex = email.indexOf('.', atIndex);
        const domainUserName = email.slice(atIndex + 1, dotIndex);
        return domainUserName;
    };

    useEffect(() => {
        if (showFilterDropDown || showFlyOut || showBulkActionDropDown || menuDropDown || loggedInDropdown || actionDropDown) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showFilterDropDown, showFlyOut, showBulkActionDropDown, menuDropDown, loggedInDropdown, actionDropDown]);
    const handleHeaderCheckboxChange = () => {
        const newCheckedState = !isAllChecked;
        setIsAllChecked(newCheckedState);
        const updatedCheckedUsers = newCheckedState
            ? finalFilteredUsers.reduce((acc: CheckedUsers, user: TeamUsers) => {
                if (
                    [
                        STATUS.NotAuthenticated,
                        STATUS.Rejected,
                        STATUS.Revoked,
                        STATUS.Authenticated,
                    ].includes(user.status) &&
                    user.role !== ROLES.ADMIN
                ) {
                    acc[user.userID] = {
                        userID: user.userID,
                        userName: user.userName,
                        email: user.email,
                        isLicensed: user?.isLicensed,
                        status: user?.status,
                        isMigrated: user?.isMigrated
                    };
                }
                return acc;
            }, {})
            : {};

        setCheckedUsers(updatedCheckedUsers);
        setSelectedCount(Object.keys(updatedCheckedUsers).length);
    };
    const handleAddCheckboxChange = (id: string, userName: string, email: string) => {
        setCheckedUsersAdd((prevCheckedUsers: CheckedUser[]) => {
            const userIndex = prevCheckedUsers.findIndex((user) => user.userID === id);
            if (userIndex !== -1) {
                return prevCheckedUsers.filter((user) => user.userID !== id);
            } else {
                return [...prevCheckedUsers, { userID: id, userName, email }];
            }
        });
    };
    const handleMultipleUsersAdd = () => {
        if (checkedUsersAdd.length > 1) {
            const users = checkedUsersAdd.map(({ userID, userName, ...rest }) => ({
                userName: userName,
                ...rest,
            }));
            setCheckedUsersAdd([])
            handleAddIcon(users,"adduser");
            setAddUserState(false);
        }
    }
    const handleUserPlus = (user: any[]) => {
        if (checkedUsersAdd.length <= 1) {
            setAddUserState(false);
            setCheckedUsersAdd([]);
            handleAddIcon(user,"adduser");

        }
    }
    const handleCheckboxChange = (id: string, userName: string, email: string, isLicensed: boolean | undefined, status: number, isMigrated: boolean | undefined) => {
        setCheckedUsers((prevCheckedUsers) => {
            const newCheckedUsers = { ...prevCheckedUsers };
            if (newCheckedUsers[id]) {
                delete newCheckedUsers[id];
                setSelectedCount(prevCount => prevCount - 1);
            } else {
                newCheckedUsers[id] = { userID: id, userName, email, isLicensed, status, isMigrated };
                setSelectedCount(prevCount => prevCount + 1);
            }
            const allSelected = finalUsers.every(user =>
                newCheckedUsers[user.userID] ||
                user.status === 1 || user.role === ROLES.ADMIN
            );
            setIsAllChecked(allSelected);
            return newCheckedUsers;
        });
    };
    const handleFilterOptionClick = (option: string) => {
        setShowFilterDropDown(false);
        const newSelectedFilters = [option];
        setSelectedFilters(newSelectedFilters);
    };
    useEffect(() => {
        setSearchTerm('');
    }, [showFilterDropDown]);

    const handleCancelClick = (option: string) => {
        const newSelectedFilters = selectedFilters.filter((filter) => filter !== option);
        setSelectedFilters(newSelectedFilters);
    };
    const handleImportClose = () => {
        setImportError("");
        setShowImportPopUp(false);
        setSelectedFile(null);
    }
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        debugger
        const files = event.target.files;
        setImportError("");
        if (files && files.length > 0) {
            setSelectedFile(files[0]);
        } else {
            setSelectedFile(null);
        }
      
    };
    const handleFileUpload = () => {
        if (!selectedFile) {
            setImportError(`<span style="color: red;">No file has been selected.</span>`);
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            const result = reader.result;
            if (result instanceof ArrayBuffer) {
                try {
                    debugger
                    const data = new Uint8Array(result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const json = XLSX.utils.sheet_to_json(sheet);
                    const keyTransformations: { [key: string]: string } = {
                        "User Name": "userName",
                        "Email": "email"
                    };
                    const trimmedJson = json.map((row: any) => {
                        const trimmedRow: any = {};
                        for (const key in row) {
                            if (row.hasOwnProperty(key)) {
                                const transformedKey = keyTransformations[key];
                                trimmedRow[transformedKey] =
                                    typeof row[key] === 'string' ? row[key].trim() : row[key];
                            }
                        }
                        return trimmedRow;
                    });

                    handleAddIcon(trimmedJson,"import").then((response) => {
                        if (response === undefined) {
                            setImportError("Please provide the necessary details such as username and email.");
                        }
                        else if (response.length === 0) {
                            setTimeout(() => {
                                setShowImportPopUp(false);
                                setSelectedFile(null);
                            }, 2000);
                        } else {

                            const errorFileUrl = exportToExcel(response, 'ErrorFile');
                            setImportError(
                                `<span style="color: red;">These users were not found. You can download the error file from <a href="${errorFileUrl}" download="ErrorFile.xlsx">here</a>.</span>`
                            );
                            // handleFileChange()
                            setSelectedFile(null);
                            if (fileInputRef.current) {
                                fileInputRef.current.value = "";
                            }
                        }
                    });
                } catch (error) {
                    setImportError(
                        `<span style="color: red;">Error processing the file. Please ensure it is a valid Excel file.</span>`
                    );
                    setSelectedFile(null);
                }
            }
        };

        reader.readAsArrayBuffer(selectedFile);
    };


    const handleSwitchUser = (user: any) => {
        setSessionItem('SlackLoginData', user);
        setHoverWorkspaceName(user.workspaceName);
        setWorkspaceName(user.workspaceName.length > 13 ? `${user.workspaceName.slice(0, 13)}...` : user.workspaceName);
        slackDefaultUsers();
        setUserSwitchTrigger((prev) => !prev);
    }

    const exportToExcel = (data: any[], name: string): string => {
        const columnDisplayNames: { [key: string]: string } = {
            userName: "User Name",
            email: "Email",
            status: "Status",
            isLicensed: "Is Licensed",
            isMigrated: "Is Migrated",
        };
        let selectedColumns: string[] = [];

        if (name === 'Template' || name=== 'ErrorFile') {
            selectedColumns = ["userName", "email"];
        } else {
            const datatype: string = slackState ? "Slack" : "Teams";
            const columnMappings: { [key: string]: string[] } = {
                Teams: ["userName", "email", "status"],
                Slack: ["userName", "email", "status", "isLicensed", "isMigrated"],
            };
            selectedColumns = columnMappings[datatype];
            if (selectedColumns.length === 0) {
                throw new Error("Invalid file type or no columns specified for export");
            }
        }

        const filteredData = data.map((item) => {
            const filteredItem: any = {};
            selectedColumns.forEach((column) => {

                if (column === "status") {
                    const statusCode = item[column];
                    const statusKey = Object.keys(STATUS).find(
                        (key) => STATUS[key as keyof typeof STATUS] === statusCode
                    );
                    filteredItem[columnDisplayNames[column]] = statusKey
                        ? statusKey.replace(/([a-z])([A-Z])/g, "$1 $2")
                        : "";
                } else {
                    filteredItem[columnDisplayNames[column]] = item[column];
                }
            });
            return filteredItem;
        });

        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const excelFile = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const excelBlob = new Blob([excelFile], { type: 'application/octet-stream' });
        const fileUrl = URL.createObjectURL(excelBlob);
        const link = document.createElement('a');
        link.href = fileUrl;
        if (name === 'ErrorFile') {
            return fileUrl;
        }
        link.download = `${name}.xlsx`;
        link.click();
        setMenuDropDown(false);
        return '';
    };
    //Activity Feed
    const activityFeedInfo = async () => {
        const bearerToken = getSessionItem('bearerToken');
        const slackLoginData = getSessionItem('SlackLoginData');
        const teamsLoginUser = getSessionItem('TeamLoginData');
        const parseData = teamState ? teamsLoginUser && JSON.parse(teamsLoginUser) : slackLoginData && JSON.parse(slackLoginData);
        const channelType = teamState ? 1 : 2;
        // const tenantIdData= teamsLoginUser&&JSON.parse(teamsLoginUser);
        // const userId = parseData.userID;
        try {
            if (bearerToken !== '') {
                const token = bearerToken;
                const istokenValid = checkTokenExpiration(token);
                if (istokenValid) {
                    const response = await fetchActivityInfo(token, channelType, parseData);
                    setActivityData(response.data);
                }
            }
        }
        catch (error: any) {
            setError(error);
        }
    };

    const getUserStatus = (statusCode: number): string => {
        const statusKey = STATUS[statusCode];
        if (!statusKey) {
            return " ";
        }
        return statusKey.replace(/([a-z])([A-Z])/g, "$1 $2");
    };
    const renderUsers = (finalFilteredUsers: TeamUsers[]) => {
        return (
            <>
                {finalFilteredUsers.length === 0 ? (
                    <tr>
                        <td colSpan={4} style={{ textAlign: 'center' }}>
                            <span className="txt-nousers">No users found.</span>
                        </td>
                    </tr>
                ) :
                    <tbody className="userdetails-body">
                        {finalFilteredUsers.map((user: TeamUsers, id: number) => (
                            <tr key={user.userID} className="userdetails-td">
                                <td>
                                    <div className="username-box">
                                        {user.role != ROLES.ADMIN && <input
                                            type="checkbox"
                                            className="square-icn"
                                            checked={!!checkedUsers[user.userID]}
                                            onChange={() => handleCheckboxChange(user?.userID, user.userName, user.email, user?.isLicensed, user.status, user?.isMigrated)}
                                            disabled={user.status === 1}
                                            style={{ cursor: user.status === 1 ? 'not-allowed' : 'pointer' }}
                                        />}
                                        {user.role === ROLES.ADMIN &&
                                            <><FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", fontSize: '1.3rem' }} /></>
                                        }
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5rem' }}>{user?.isLicensed && <FontAwesomeIcon icon={faKey} style={{ color: "#3FBCE8" }} />}
                                            <span className="key-userName" title={user.userName}  >  {user.userName.length > 20 ? user.userName.slice(0, 20) + '...' : user.userName} </span>
                                        </div>
                                    </div>
                                </td>
                                <td>{user.email}</td>
                                {Object.keys(checkedUsers).length > 1 && <td><span className="statusbutton">{getUserStatus(user.status)}</span> </td>}
                                {Object.keys(checkedUsers).length <= 1 && <td>{getUserStatus(user.status)}
                                    <span className="Tooltip">
                                        <FontAwesomeIcon icon={faCircleInfo} className="infoIcon" />
                                        <span className="tooltiptext">{getinfotext(user, user.status)}</span>
                                    </span>
                                </td>}

                                {Object.keys(checkedUsers).length <= 1 && <td style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>{getStatusComponent(user, user.status)}{user?.isMigrated && <img src={arrowLogo} className="arrow-logo" alt="arrowlogo" width={20} height={20}/>}</td>}
                            </tr>
                        ))}
                    </tbody>
                }
                <ConfirmPopUp
                    isOpen={isModalOpen}
                    onClose={closePopUP}
                    onConfirm={confirmAction}
                    message={modalMessage}
                    messageStyle={{ color: "black" }}
                />
                <ConfirmPopUp
                    isOpen={isResultModal}
                    onClose={closePopUP}
                    onConfirm={okAction}
                    message={modalMessage}
                    messageStyle={{ color: isError ? 'red' : 'black' }}
                />
            </>
        );
    };


    const formatRelativeTime = (utcTimestamp: string): string => {
        const currentTimeLocal = moment();


        const backendTimeUTC = moment.utc(utcTimestamp);


        const diffInSeconds = currentTimeLocal.diff(backendTimeUTC, 'seconds');
        const diffInMinutes = currentTimeLocal.diff(backendTimeUTC, 'minutes');
        const diffInHours = currentTimeLocal.diff(backendTimeUTC, 'hours');
        // let diffInDays = currentTimeLocal.diff(backendTimeUTC, 'days');

        if (diffInSeconds < 60) {
            return `less than a minute ago`;
        } else if (diffInMinutes < 60) {
            if (diffInMinutes === 1)
                return `${diffInMinutes} minute ago`;
            else
                return `${diffInMinutes} minutes ago`;
        } else if (diffInHours < 24) {
            if (diffInHours === 1)
                return `${diffInHours} hour ago`;
            else
                return `${diffInHours} hours ago`;
        } else {
            const diffInDays = Math.floor(diffInHours / 24);
            if (diffInDays === 1)
                return `${diffInDays} day ago`;
            else
                return `${diffInDays} days ago`;
        }

    };

    const groupAndFilterActivityData = (data: any[]) => {
        const groupedData: { [key: string]: any[] } = {};

        data.forEach((item) => {
            const utcDate = new Date(item.createdDate).toISOString();
            const dateKey = utcDate.split('T')[0];

            if (!groupedData[dateKey]) {
                groupedData[dateKey] = [];
            }
            groupedData[dateKey].push({ ...item, utcDate });

        });
        Object.keys(groupedData).forEach((dateKey) => {
            groupedData[dateKey].sort((a, b) => {
                const timeA = new Date(b.createdDate).getTime();
                const timeB = new Date(a.createdDate).getTime();
                return timeA - timeB;
            });
        });

        return Object.keys(groupedData)
            .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
            .map((dateKey) => ({
                date: dateKey,
                feeds: groupedData[dateKey],
            }));
    };

    const groupedActivityData = groupAndFilterActivityData(activityData);


    const addusersclass = addTeamUsers.length === 0 ? "addUsersDisable" : "addUsersEnable";


    const addUsers = (addFinalFilteredUsers: TeamUsers[] = []) => {
        return (
            <>
                {addFinalFilteredUsers.length === 0 ? (
                    <tr style={{ display: 'flex', justifyContent: 'center' }}>
                        <span className="noUsersText">No users found.</span>
                    </tr>
                ) :
                    <tbody className="adduserdetails-body">
                        {addFinalFilteredUsers.map((user: any, index: number) => (
                            <tr className="adduserdetails-td">
                                <td className="add-user-checkbox">
                                    <input
                                        type="checkbox"
                                        className="square-icn"
                                        checked={!!checkedUsersAdd.find((u) => u.userID === user.userID)}
                                        onChange={() => handleAddCheckboxChange(user.userID, user.userName, user.email)}
                                    />
                                    <div
                                        style={{
                                            backgroundColor: colors[index % colors.length],
                                            width: '30px',
                                            height: '31px',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: '#fff',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {getInitials(user.userName)}
                                    </div>
                                </td>
                                <td>
                                    <div className="addusername-box">
                                        <span className="key-userName" title={user.userName}  >  {user.userName.length > 15 ? user.userName.slice(0, 15) + '...' : user.userName} </span>
                                    </div>
                                </td>
                                <td className="userEmail">{user.email}</td>

                                <td>
                                    <div>
                                        <img src={plus} alt="adduser" className="adduserplus" style={{ cursor: checkedUsersAdd.length < 2 ? 'pointer' : 'not-allowed' }} onClick={() => handleUserPlus([user])} />
                                    </div>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                }
            </>
        )
    }

    return (
        <>
            <Header />
            <div className="body">
                <div className="tab-navigation" >
                    <div className={`${slackState ? "slack-tab" : "slack-disable"} ${slackState === true && showFlyOut ? "activelog" : ""}`} >
                        <img src={slackState ? slacklogo : slackDisable} onClick={slackClicked} className={slackState ? "slacktab-logo" : "slacktab-diable"} alt="slacklogo" />
                        <span className='tabtext' onClick={slackClicked}>Slack</span>
                    </div>
                    <img src={arrowLogo} alt="arrowlogo" />
                    <div className={`${teamState ? "team-tab" : "team-disable"} ${teamState === true && showFlyOut ? "activelog" : ""}`}>
                        <img src={teamState ? teamslogo : teamsDisable} onClick={teamClicked} className="teamtab-logo" alt="teamtab" />
                        <span className='tabtext' onClick={teamClicked}>Teams </span>
                    </div>
                </div>
                <div className="metrics">
                    <div className={`metricssection `}>
                        <div className={`loggedIn-metrics`}>
                            {slackState && (
                                <div className="d-flex">
                                    <span className="slackusertext">
                                        Workspace :
                                    </span>
                                    <span title={hoverWorkspaceName} className="d-flex">{workspaceName} <img src={downarrow} className="downarrowlogo dropdown-toggle" alt="downarrow" onClick={toggleDropdown} /></span>
                                    {loggedInDropdown && (
                                        <div className="dropdown-menu show" aria-labelledby="dropdownMenuButton" ref={dropdownRef}>
                                            <ul style={{ padding: 0, textAlign: 'left',marginBottom:0 }} onClick={toggleDropdown}>
                                                {workSpaceUsers.map((user: any) => (
                                                    <li
                                                        key={user.userId}
                                                        className="dropdown-item menu-option switch-Account"
                                                        onClick={() => handleSwitchUser(user)}
                                                        title={user.workspaceName}
                                                    >
                                                        {user.workspaceName}
                                                    </li>
                                                ))}
                                                <li className="dropdown-item menu-option add-user d-flex flex-row align-items-center" onClick={handleSlackUser} >+ Workspace</li>
                                            </ul>
                                        </div>
                                    )}
                                </div>)
                            }
                            {isLoading && (
                                <div className="spinner-container">
                                    <img
                                        src={spinnerGif}
                                        alt="Loading..."
                                        className="spinner-gif"

                                    />
                                </div>
                            )}
                            {teamState && (
                                <div>
                                    <span className="teamstext">
                                        Domain :
                                    </span>
                                    <span title={hoverdomainName}>{domainName}</span>
                                    {/* <img src={downarrow} className="downarrowlogo" alt="downarrow" onClick={() => { setLoggedInDropdown(true) }} />
                                    {loggedInDropdown && (
                                        <div className="dropdown-menu show" aria-labelledby="dropdownMenuButton" ref={dropdownRef}>
                                            <ul style={{ padding: 0 }} onClick={toggleDropdown}>
                                                <li className="dropdown-item menu-option add-user" onClick={handleTeamUser}><img src={AddUserIcon} alt="album" className="album" />Add User</li>
                                            </ul>
                                        </div>
                                    )} */}
                                </div>
                            )}
                            <div className="logged-in">
                                <span className="loggedIn-user">
                                    Logged In as
                                </span>
                                <span className="loggedIn-userName" title={hoverLoggedInuserName}>{loggedInuserName}
                                    <FontAwesomeIcon icon={faRightFromBracket} onClick={() => setNavModel(true)} className="rightArrowIcon" />
                                </span>
                                {slackState && (<ConfirmationModal
                                    isOpen={navModel}
                                    onClose={closeModal}
                                    onConfirm={handleSlackUser}
                                    message={'Clicking on "Proceed" will log you out from Slack account.'} />)}
                                {teamState && (<ConfirmationModal
                                    isOpen={navModel}
                                    onClose={closeModal}
                                    onConfirm={handleTeamUser}
                                    message={'Clicking on "Proceed" will log you out from Teams account.'} />)}
                            </div>
                        </div>
                        <div className={`usermetrics `}>
                            <div className="usermetricsvalue" onMouseLeave={() => setUserMetrics(false)}>
                                <div>
                                    <span className="metricsvalue">{totalUsers}</span>
                                    <img src={downarrow} className="downarrowlogo" onMouseEnter={() => setUserMetrics(true)} alt="downarrow" />
                                    {usermetrics &&
                                        <ul className={`metrics-list ${usermetrics ? 'visible' : ''}`}>
                                            <li>Total Users: {totalUsers}</li>
                                            <li>Authenticated Users: {authenticatedUsers}</li>
                                            <li>Requested Users: {notAuthenticatedUsers}</li>
                                        </ul>
                                    }
                                </div>
                                <div>
                                    <img src={adminmetrics} className="adminlogo" alt="adminmetrics"></img>
                                </div>
                            </div>
                            <div className="usermetricstext">
                                User Metrics
                            </div>
                        </div>
                        {slackState && (<div className={`migratermetrics `}>
                            <div className="usermetricsvalue" onMouseLeave={() => setmrgmetrics(false)}>
                                <div>
                                    <span className="metricsvalue">{(licenseUsers || 0) + (externalLicense || 0)}</span>
                                    <img src={downarrow} className="downarrowlogo" onMouseEnter={() => setmrgmetrics(true)} alt="downarrow" />
                                    {mrgmetrics &&
                                        <ul className={`metrics-list ${mrgmetrics ? 'visible' : ''}`}>
                                            <li>Total Licenses: {workspaceMatrix?.totalLicenses === -1 ? "Unlimited" :workspaceMatrix?.totalLicenses }</li>
                                            <li>External Licenses: {externalLicense}</li>
                                            <li>Internal Licenses: {licenseUsers}</li>
                                        </ul>
                                    }
                                </div>
                                <div>
                                    <img src={adminmetrics} className="adminlogo" alt="adminmetrics"></img>
                                </div>
                            </div>
                            <div className="usermetricstext">
                                License Metrics
                            </div>
                        </div>)}
                        {slackState && (<div className="workspacemetrics">
                            <div className="usermetricsvalue" onMouseLeave={() => setwrkspace(false)}>
                                <div>
                                    <span className="metricsvalue">{licenseUsers}</span>
                                    <img src={downarrow} className="downarrowlogo" onMouseEnter={() => setwrkspace(true)} alt="downarrow" />
                                    {wrkspacemetrics &&
                                        <ul className={`metrics-list ${wrkspacemetrics ? 'visible' : ''}`}>
                                            <li>Licensed Users: {licenseUsers}</li>
                                            <li>Migrated Users: {migratedUsers}</li>
                                            <li>Unmigrated Users: {licenseUsers - migratedUsers}</li>
                                        </ul>
                                    }
                                </div>
                                <div>
                                    <img src={workspaceicon} className="adminlogo" alt="workspace"></img>
                                </div>
                            </div>
                            <div className="usermetricstext">
                                Workspace Metrics
                            </div>
                        </div>)}
                    </div>
                    {/* activity-log */}
                    <div>
                        <div className="activity-log" onClick={toggleFlyout}>
                            <FontAwesomeIcon icon={faHistory} className="history-icon" />
                            <span className="activity">Activity Log</span>
                            <FontAwesomeIcon icon={faArrowRight} className="right-arrow" />
                        </div>
                        {showFlyOut ?
                            <div className="popup-container">
                                <div className="activity-feed">
                                    <div className="activity-heading">
                                        <div className="activity-bar"><img src={teamState ? teamslogo : slacklogo} onClick={teamClicked} className={`${teamState ? "activityteamslogo" : "activityslacklogo"}`} alt="teamtab" />{teamState ? "Teams " : "Slack "}Activity Feed  </div>
                                        <div onClick={handleCloseLog} className="close"> <img src={close} alt="close" /> </div>
                                    </div>
                                    <div className="activity-content">
                                        {groupedActivityData.map((dataItem, dataIndex) => (
                                            <div key={dataIndex}>
                                                <div className="date-string">
                                                    {new Date(dataItem.date).toLocaleDateString('en-GB', {
                                                        day: '2-digit',
                                                        month: 'short',
                                                        year: 'numeric',
                                                    })}
                                                </div>

                                                {dataItem.feeds.map((item: any, index: any) => (
                                                    <div className="act-items" key={index}>
                                                        <div>
                                                            <FontAwesomeIcon
                                                                icon={faCircle}
                                                                style={{ color: item.borderCode }}
                                                                className="circle-icn"
                                                            />
                                                        </div>
                                                        <div
                                                            className="activity-item"
                                                            style={{
                                                                backgroundColor: item.colorCode,
                                                                borderLeft: `2px solid ${item.borderCode}`,
                                                                color: item.colorCode === '#fff' ? '#293042' : '#fff',
                                                            }}
                                                        >
                                                            <span style={{ fontWeight: 'bold', fontSize: '0.9em', marginRight: '5px' }}>
                                                                {item.message.split(',')[0]}
                                                            </span>
                                                            {item.message.substring(item.message.indexOf(',') + 1).trim()}
                                                            <span
                                                                className="days-ago"
                                                                style={{
                                                                    marginLeft: '10px',
                                                                    color: item.colorCode === '#fff' ? '#EBEEF2' : '#fff',
                                                                    fontSize: '0.8em',
                                                                }}
                                                            >
                                                                <span className="activityDate">({formatRelativeTime(item.createdDate)})</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
                <div className="dashboard-table">
                    <div className="second-nav">
                        <div className="second-nav-left">
                            {selectedFilters.length > 0 ? (
                                <div className="selected-filters">
                                    <label style={{ alignSelf: "center" }}>Applied Filters :  </label>
                                    {selectedFilters.map((filter) => (
                                        <div key={filter} className="selected-filter">
                                            <span>{filter}</span>
                                            <FontAwesomeIcon icon={faTimes} onClick={() => handleCancelClick(filter)} style={{ color: '#F62626' }} className="cancel-icon" />
                                        </div>
                                    ))}
                                </div>
                            ) :
                                <>
                                    <button className="user-fetch" onClick={teamState ? handleAddTeamsUsers : handleAddSlacksUsers}>Add Users +</button>
                                    {(addUserstate) && (
                                        <div className="adduserPop">
                                            {addusersclass === "addUsersEnable" && <div className={`${addusersclass} addusers`}>
                                                <div>
                                                    <table className="adduser-table">
                                                        <thead>
                                                            <td>
                                                                <div className="adduser-search">
                                                                    <span className="searchnames">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Search by name or email address"
                                                                            className="addSearch-input"
                                                                            value={addSearchTerm}
                                                                            onChange={(e) => setAddSearchTerm(e.target.value)}
                                                                        />
                                                                        <span onClick={handleCloseLog} className="close">
                                                                            <img src={close} alt="close" />
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                {addUsers(addFinalFilteredUsers)}
                                                            </td>
                                                        </thead>
                                                    </table>
                                                </div>
                                                <div className="adduserpagination-container">
                                                    <div><label className="results-label">Results Per Page:</label>
                                                        <select value={addPageSize} onChange={handleAddUserPageSizeChange} className="page-size-dropdown">
                                                            {pageSizeOptions.map((size) => (
                                                                <option key={size} value={size}>
                                                                    {size}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <button className={`${checkedUsersAdd.length > 1 ? "user-fetch" : "user-fetch-disable"}`}
                                                        style={{
                                                            cursor: checkedUsersAdd.length > 1 ? 'pointer' : 'not-allowed',
                                                            opacity: checkedUsersAdd.length > 1 ? 1 : 0.5
                                                        }}
                                                        onClick={() => handleMultipleUsersAdd()}>
                                                        Add
                                                    </button>
                                                    <ReactPaginate
                                                        previousLabel={"<-"}
                                                        nextLabel={"->"}
                                                        breakLabel={"..."}
                                                        pageCount={addPageCount}
                                                        marginPagesDisplayed={1}
                                                        pageRangeDisplayed={1}
                                                        onPageChange={handleAddUserPageClick}
                                                        forcePage={addCurrentPage >= addPageCount ? addPageCount - 1 : addCurrentPage}
                                                        containerClassName={"pagination"}
                                                        activeClassName={"active"}
                                                    />
                                                </div>

                                            </div>}
                                            {addusersclass === "addUsersDisable" && <div className={`${addusersclass} noUsers`}>
                                                <div className="noUserContent">
                                                    <div className="txt-nousers">No users left to add</div>
                                                    <button onClick={handleCloseLog} className="ok-button">OK</button>
                                                </div>
                                            </div>}

                                        </div>
                                    )}
                                    <label className="fetch-all-users" onClick={teamState ? handleFetchAllUsers : handleFetchAllSlackUsers}>Fetch all users</label>
                                </>}
                        </div>
                        {selectedFilters.length > 0 && <div style={{ width: "15%" }}>    <button className="user-fetch clear-all" onClick={handleClearAll}>Clear All</button></div>}
                        <div className="second-nav-middle">
                            <div className="user-search"> <img src={searchicon} alt={dummyimage} height={18} width={18} className="search-icon" />
                                <div className="search-container"> <input type="text" placeholder="Search" className="search-input" value={searchTerm}
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                    }} />
                                    {searchTerm !== '' && <span onClick={handleCloseSearch} className="close">
                                        <img src={close} alt="close" />
                                    </span>}
                                </div>
                            </div>
                            {Object.keys(checkedUsers).length > 1 && <span className="bulk-action" onClick={() => { setShowBulkActionDropDown(true) }}>Bulk Actions<FontAwesomeIcon icon={faCaretDown} className="caret-icon" />
                                {showBulkActionDropDown && (
                                    <div className="bulk-action-dropdown" ref={dropdownRef}>
                                        <ul style={{ padding: 0 }}>
                                            <li className="bulk-action-option" onClick={() => openModal(checkedUsers, "Are you sure you want to send the bulk authentication requests?", ACTIONS.AUTHENTICATE)}><i className="bi bi-person-fill-check"></i> Authenticate</li>
                                            <li className="bulk-action-option" onClick={() => openModal(checkedUsers, "Are you sure you want to bulk revoke?", ACTIONS.REVOKE)}><i className="bi bi-person-fill-x"></i> Revoke</li>
                                            {slackState && <li className="bulk-action-option" onClick={() => openModal(checkedUsers, "Are you sure you want to add bulk licenses?", ACTIONS.ADD_LICENSE)}>+ Add License</li>}
                                            {slackState && <li className="bulk-action-option" onClick={() => openModal(checkedUsers, "Are you sure you want to remove bulk licenses ?", ACTIONS.REMOVE_LICENSE)}>- Remove License</li>}
                                        </ul>
                                    </div>
                                )}
                            </span>}
                        </div>
                        <div className="second-nav-right">
                            <FontAwesomeIcon icon={faRotateRight} onClick={handleRefresh} style={{ paddingTop: 15 }} />
                            <button className="filter" onClick={() => { setShowFilterDropDown(true) }}><label className="filter-label">Filter</label> <img src={filtericon} alt={dummyimage} height={10} width={10} />
                                {showFilterDropDown && (
                                    <div className="filter-dropdown" ref={dropdownRef}>
                                        <ul>
                                            {filterOptions
                                                .filter((option) => {
                                                    if (teamState) {
                                                        return option.value !== "Licensed" && option.value !== "Migrated";
                                                    }
                                                    return true;
                                                })
                                                .map((option) => (
                                                    <li
                                                        key={option.value}
                                                        className="filter-option"
                                                        onClick={() => handleFilterOptionClick(option.value)}
                                                    >
                                                        {option.label}
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                )}
                            </button>
                            <div></div>
                            <FontAwesomeIcon icon={faEllipsisVertical} className="ellipses" onClick={() => { setMenuDropDown(true) }} />
                            {menuDropDown && (
                                <div className="filter-dropdown menu-dropdown downloadtemplate" ref={dropdownRef}>
                                    <ul style={{ padding: 0 }} className="filter-dropdown-ul">
                                        <li className="bulk-action-option menu-option" onClick={() => exportToExcel(templateData, "Template")}>
                                            <FontAwesomeIcon icon={faDownload} />&nbsp;
                                            Template</li>
                                        <li className="bulk-action-option menu-option" onClick={handleImport}> <FontAwesomeIcon icon={faFileImport} />&nbsp; Import</li>
                                        <li className="bulk-action-option menu-option" onClick={() => exportToExcel(finalPaginatedUsers, "Data")}> <FontAwesomeIcon icon={faFileExport} />&nbsp;Export</li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="user-details">

                        {loading ? <p>Loading...</p> :
                            <table className="user-table">
                                <thead>
                                    <tr className="thead-row">
                                        <th>
                                            <div className="header-username">
                                                <input
                                                    type="checkbox"
                                                    className="square-icn"
                                                    checked={isAllChecked}
                                                    onChange={handleHeaderCheckboxChange}
                                                />
                                                <div>Name<TbArrowsSort className="sort-btn" onClick={() => handleSortButtonClick('userName')} /></div>
                                            </div>
                                        </th>
                                        <th>Email <TbArrowsSort className="sort-btn" onClick={() => handleSortButtonClick('email')} /></th>
                                        <th>Status<TbArrowsSort className="sort-btn" onClick={() => handleSortButtonClick('status')} /></th>
                                        {Object.keys(checkedUsers).length <= 1 && <th>Action</th>}
                                    </tr>
                                </thead>
                                {renderUsers(finalFilteredUsers)}
                            </table>}

                    </div>
                    {finalFilteredUsers.length !== 0 &&
                        <div className="pagination-container">
                            <div><label className="results-label">Results Per Page:</label>
                                <select value={pageSize} onChange={handlePageSizeChange} className="page-size-dropdown">
                                    {pageSizeOptions.map((size) => (
                                        <option key={size} value={size}>
                                            {size}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <ReactPaginate
                                previousLabel={"<-"}
                                nextLabel={"->"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageClick}
                                forcePage={currentPage}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    }
                    {showImportPopUp &&
                        <div className='import-popup' >
                            <div className='import'>
                                <div className="folder-title"><div className="folder-title-icon"><FontAwesomeIcon icon={faFolder} className="folder" /> <label style={{ alignContent: "center" }}>Import Users</label></div><FontAwesomeIcon icon={faXmark} style={{ cursor: "pointer" }} onClick={handleImportClose} /></div>
                                <label className="Name">Please upload the file
                                </label>
                                <input
                                    placeholder="Folder Name"
                                    className="folder-input"
                                    type="file"
                                    accept=".xlsx,.xls,.csv"
                                    ref={fileInputRef}
                                    onChange={(e:ChangeEvent<HTMLInputElement>)=>handleFileChange(e)}
                                />
                                <div className="importErrorbutton">
                                    {importError && (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: importError,
                                            }}
                                            style={{ color: 'red', fontSize: '12px' }}
                                        />
                                    )}
                                    {!importError && <div></div>}
                                    <span className="importbutton" ><button className="importSubmit" onClick={() => handleFileUpload()}>Submit</button></span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {error !== '' &&
                <div className="confirmation">
                    <div className="logout-confirm">
                        <p className="logout-text">{error.message}</p>
                        <button onClick={() => handleErrorOk()} className="proceedButton">OK</button>
                    </div>
                </div>
            }
        </>
    )
}

export default DashBoardChannel;


