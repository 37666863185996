import { useContext, useEffect, useRef, useState } from 'react';
import '../admin/adminloginscreen.css';
import APIURL, { ADMINACCOUNT, APIURLENTERPRISE, TEAMSACCOUNT, TEMPUSER } from "../../common/ApiConfig";
import 'bootstrap/dist/css/bootstrap.min.css';
import saketa from "../../assets/saketa.png";
import failed from "../../assets/failedpic.png"
import microsoftlogo from "../../assets/microsoftlogo.png";
import success from "../../assets/success.gif";
import slack from '../../assets/slack.png';
import to from '../../assets/arrow-1.png';
import teams from '../../assets/teams.png';
import close from '../../assets/close.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GlobalStateContext } from '../../common/GlobalState';
import eclipse from '../../assets/eclipse.png';
import { addResourceGroup, addStorageAccountName, authenticateAdmin, authenticateEmailUser, authenticateTempUser, configureOrg, configureOrganization, generateOAuthUrl, GetBlobAccount, getRegions, GetResourceGroup } from '../../common/authServices';
import { AUTH, EMAIL, ROLES, SLACKAUTH } from '../../common/constants';
import spinnerGif from "../../assets/saketa-loading.gif";
import { getSessionItem, setSessionItem, checkTokenExpiration } from '../../common/helper';
import Draggable from 'react-draggable';
import { ResourceData, SubscriptionData } from '../../common/interfaces';
import Select, { StylesConfig } from 'react-select';
import Cookies from 'js-cookie';

function AdminLoginScreen() {

    // useDisableContextAndF12();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    // const [isStorageChecked, setStorageIsChecked] = useState<boolean>(false);
    // const [isDBChecked, setIsDBChecked] = useState<boolean>(false);
    const [error, setError] = useState<any>('')
    const { currentStep, setCurrentStep } = useContext(GlobalStateContext); //future reference
    const [isLoading, setIsLoading] = useState(false);
    const [isEnterpriseAccount, setIsEnterpriseAccount] = useState<boolean>(false);
    const [newOption, setNewOption] = useState<string>('');
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [shouldDisplayCheckbox, setShouldDisplayCheckBox] = useState<boolean>(true);
    const [addTo, setAddTo] = useState<'resourceGroup' | 'blobStorage' | ''>('');
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [subscriptions, setSubscriptions] = useState<any[]>([]);
    const [regions, setRegions] = useState<any[]>([]);
    const [resourceGroups, setResourceGroups] = useState<any[]>([]);
    const [blobStorageAccounts, setBlobStorageAccounts] = useState<any[]>([]);
    const [subscriptionData, setSubscriptionData] = useState<SubscriptionData>({
        subscriptionName: '',
        subscriptionId: '',
        azureResourceGroups: [],
    });
    const [region, setRegion] = useState<string>('');
    const [resourceGroup, setResourceGroup] = useState<ResourceData>({
        resourceGroupId: '',
        resourceGroupName: ''
    });

    const [BlobAccount, setBlobAccount] = useState<string>("");
    const customStyles: StylesConfig = {
        indicatorSeparator: () => ({}),
        container: (base) => ({
            ...base,
            width: '75%',
            color: 'black',
            cursor: 'pointer'
        }),
        control: (base) => ({
            ...base,
            fontSize: '1rem',
            borderRadius: '6px',
            backgroundColor: '#fff',
            transition: 'all 0.3s ease',
            width: '100%',
        }),
        menu: (base) => ({
            ...base,
            zIndex: 1000,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'rgb(8, 152, 204)'
                : state.isFocused
                    ? 'rgb(143, 204, 234)'
                    : 'white',
            cursor: 'pointer',
            ':active': {
                backgroundColor: 'rgb(8, 152, 204)',
            },
        }),

    };
    const customStylesGroups: StylesConfig = {
        container: (base) => ({
            ...base,
            width: '100%',
            color: 'black',
            cursor: 'pointer'
        }),
        control: (base) => ({
            ...base,
            fontSize: '1rem',
            borderRadius: '6px',
            backgroundColor: '#fff',
            transition: 'all 0.3s ease',
            width: '100%',
        }),
        menu: (base) => ({
            ...base,
            zIndex: 1000,
        }),
        indicatorSeparator: () => ({}),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'rgb(8, 152, 204)'
                : state.isFocused
                    ? 'rgb(143, 204, 234)'
                    : 'white',
            cursor: 'pointer',
            ':active': {
                backgroundColor: 'rgb(8, 152, 204)',
            },
        }),
    };

    // const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        const cookieValue = Cookies.get('checkboxState');
        const shouldDisplayCheckbox = cookieValue === 'true' || cookieValue === undefined;
        setShouldDisplayCheckBox(shouldDisplayCheckbox)
        const enterpriseAccount = cookieValue === 'true' ? true : false;
        setIsEnterpriseAccount(enterpriseAccount)
        const state = searchParams.get('state');
        if (currentStep === AUTH.FAILED) {
            return
        }
        else if (state) {
            setCurrentStep(EMAIL.LOGIN)
            return
        }
        else {
            setCurrentStep(AUTH.ADMINLOGIN)
        }
    }, [])
    const handleAdminAccountLogin = async () => {
        try {
            const scopes = isEnterpriseAccount ? ADMINACCOUNT.SCOPES_ENTERPRISE : ADMINACCOUNT.SCOPES;
            setSessionItem('isEnterpriseAccount', isEnterpriseAccount);
            const oauthUrl = generateOAuthUrl(ADMINACCOUNT.CLIENT_ID, ADMINACCOUNT.RESPONSE_TYPE, scopes, ADMINACCOUNT.PROMPT, ADMINACCOUNT.REDIRECT_URI);
            window.location.href = oauthUrl;
        }
        catch (error) {
        }
    };
    const handleTempUserAccountLogin = async () => {
        try {
            const oauthUrl = generateOAuthUrl(ADMINACCOUNT.CLIENT_ID, ADMINACCOUNT.RESPONSE_TYPE, TEAMSACCOUNT.SCOPES, TEAMSACCOUNT.PROMPT, ADMINACCOUNT.REDIRECT_URI);
            window.location.href = oauthUrl;
        }
        catch (error) {
            console.error('Error during login:', error);
        }
    }

    const handleAdminAccount = async (loginCode: string, state: string | null) => {
        try {
            setIsLoading(true);
            const enterpriseAccount = getSessionItem('isEnterpriseAccount')
            const api = enterpriseAccount ? APIURLENTERPRISE : APIURL
            const adminResponse = await authenticateAdmin(loginCode, api, state);
            const response = await adminResponse.json()

            if (response.data.role) {
                if (response.data.role === ROLES.ADMIN) {
                    setIsLoading(false);
                    const adminData = {
                        userName: response.data.userName,
                        tenantID: response.data.tenantID,
                        userID: response.data.userID,
                        email: response.data.email,
                        role: response.data.role
                    }
                    const slackWorkSpaceData = response.data.slackWorkspaces;
                    const teamDomainData = response.data.teamsAccounts;
                    const lMetrics = response.data.lMetrics;
                    const blobAccount = response.data.storageAccName;
                    setSessionItem('adminData', adminData);
                    slackWorkSpaceData ? setSessionItem('slackWorkSpaceData', slackWorkSpaceData) : setSessionItem('teamDomainData', [])
                    teamDomainData ? setSessionItem('teamDomainData', teamDomainData) : setSessionItem('teamDomainData', [])
                    setSessionItem('lMetrics', lMetrics);
                    setSessionItem('blobAccount', blobAccount);
                    setSessionItem('bearerToken', response.data.token)
                    setSessionItem('tempUser', false)
                    setCurrentStep(AUTH.SUCCESS);
                    if (shouldDisplayCheckbox) {
                        Cookies.set('checkboxState', String(enterpriseAccount), { expires: 180 });
                    }
                }
                else if (response.data.role === ROLES.TEMP_USER) {
                    setIsLoading(false);
                    // setIsModalOpen(true);
                    const adminData = {
                        userName: response.data.userName,
                        tenantID: response.data.tenantID,
                        userID: response.data.userID,
                        email: response.data.email,
                        role: response.data.role
                    }
                    const slackWorkSpaceData = response.data.slackWorkspaces;
                    const teamDomainData = response.data.teamsAccounts;
                    const lMetrics = response.data.lMetrics;
                    slackWorkSpaceData ? setSessionItem('slackWorkSpaceData', slackWorkSpaceData) : setSessionItem('teamDomainData', [])
                    teamDomainData ? setSessionItem('teamDomainData', teamDomainData) : setSessionItem('teamDomainData', [])
                    setSessionItem('lMetrics', lMetrics);
                    setSessionItem('adminData', adminData);
                    setSessionItem('bearerToken', response.data.token)
                    setSessionItem('tempUser', true);
                    setCurrentStep(AUTH.TEMPUSER)
                }
                else if (response.data.role === ROLES.USER) {
                    if (state) {
                        const URL = response.data.redirectUrl;
                        const userData = {
                            userName: response.data.userName,
                            tenantID: response.data.tenantID,
                            userID: response.data.userID,
                            token: response.data.token
                        }
                        setSessionItem('userData', userData);
                        window.location.href = URL;
                    }
                    else {
                        setError("Invalid Credentials")
                        setCurrentStep(AUTH.FAILED);
                    }
                }
                else {
                    setError("You doesnt have valid credentials to login")
                    setCurrentStep(AUTH.FAILED);
                }

            }
            else {
                setError("You are not a user")
                setCurrentStep(AUTH.FAILED);
            }
        }
        catch (error: any) {
            if (state) {
                if (error) {
                    window.location.href = process.env.REACT_APP_ERROR ? process.env.REACT_APP_ERROR : "";
                }
            }
            else if (error?.message === "Access with Azure blob storage only.") {
                setCurrentStep(AUTH.BLOB)
            }
            else {
                setError(error.message)
                setCurrentStep(AUTH.FAILED);
            }
            // setError(ERROR_MESSAGES.FETCH_ADMIN_DETAILS_ERROR)
        }
        finally {
            setIsLoading(false);
        }
    }
    const handleUserAccount = async (loginCode: string, state: string) => {
        try {
            setIsLoading(true);
            const adminResponse = await authenticateEmailUser(loginCode, state);
            const response = await adminResponse.json()
            if (response.data.role) {
                if (response.data.role === ROLES.USER) {
                    if (state) {
                        const URL = response.data.redirectUrl;
                        const userData = {
                            userName: response.data.userName,
                            tenantID: response.data.tenantID,
                            userID: response.data.userID,
                            token: response.data.token,
                            workspace: response.data.workspaceName
                        }
                        setSessionItem('userData', userData);
                        window.location.href = URL;
                    }
                    else {
                        setError("Invalid Credentials")
                        setCurrentStep(AUTH.FAILED);
                    }
                }
                else {
                    setError("You doesnt have valid credentials to login")
                    setCurrentStep(AUTH.FAILED);
                }

            }
            else {
                setError("You are not a user")
                setCurrentStep(AUTH.FAILED);
            }
        }
        catch (error: any) {
            if (state) {
                if (error) {
                    window.location.href = process.env.REACT_APP_ERROR ? process.env.REACT_APP_ERROR : "";
                }
            }
        }
        finally {
            setIsLoading(false);
        }
    }
    const handleGifLoad = () => {
        setTimeout(() => {
            setCurrentStep(SLACKAUTH.SLACKLOGIN)
            navigate('/dashboard');
        }, 2500);

    };
    useEffect(() => {
        const error = searchParams.get('error');
        const adminData = getSessionItem('adminData');
        const parsedata = JSON.parse(adminData)
        const teamLoginData = getSessionItem('TeamLoginData');
        const state = searchParams.get('state');
        const code = searchParams.get('code');
        const session_state = searchParams.get('session_state');
        const tempUser = getSessionItem('tempUser');
        const AzureRM = getSessionItem('AzureRM');
        const parsedataAzure = JSON.parse(AzureRM)
        if (parsedata?.role === ROLES.ADMIN) {
            navigate('/dashboard');
            return
        }
        if (tempUser && code) {
            handleTempUserLogin(code)
            return
        }

        if (state) {
            setSessionItem('state', state);
        }
        if (error && currentStep === AUTH.ADMINLOGIN) {
            setCurrentStep(AUTH.FAILED)
            setError(error)
            return
        }

        else if (error) {
            if (error === "invalid_client") {
                setError('Please log in using valid client credentials.')
            }
            else {
                setError(error)
            }
            setCurrentStep(AUTH.FAILED)
            return
        }

        if (session_state && code && !tempUser && !AzureRM && !state) {
            if (session_state !== null)
                handleAdminAccount(code, state);
        }
        if (parsedata?.role !== ROLES.ADMIN && !tempUser && parsedataAzure?.role === ROLES.AZURE_RM) {
            setSubscriptions(parsedataAzure.azureSubscriptions);
            handleRegions(parsedataAzure);
        }
        if (session_state && code && state) {
            if (session_state !== null)
                handleUserAccount(code, state);
        }
        if (teamLoginData) {
            navigate('/dashboardchannel');
        }

    }, [searchParams]);
    const handleRegions = async (azureRM: any) => {
        try {
            setIsLoading(true)
            const response = await getRegions(azureRM)
            const regions = await response.json()

            if (regions) {
                setSessionItem('tempUser', false)
                setRegions(regions.data)
                setCurrentStep(AUTH.TEMP_USER_CONFIG)

            }
        }
        catch (error: any) {
            setError(error?.message?.length > 200 ? error.message.slice(0, 100) + '...' : error.message);
        }
        finally {
            setIsLoading(false)
        }

    }
    const handleTempUserLogin = async (loginCode: string) => {
        const bearerToken = getSessionItem('bearerToken')
        if (bearerToken !== '') {
            const token = bearerToken;
            const istokenValid = checkTokenExpiration(token);
            if (istokenValid) {
                try {
                    setIsLoading(true);
                    const storageAccountDetails = await authenticateTempUser(loginCode)
                    const response = await storageAccountDetails.json();

                    if (storageAccountDetails) {
                        setSessionItem('AzureRM', response.data)
                        setSubscriptions(response.data.azureSubscriptions)
                        await handleRegions(response.data);


                    }

                }

                catch (error: any) {
                    setIsLoading(false);
                    setError(error?.message?.length > 200 ? error.message.slice(0, 110) + '...' : error.message);

                }
                finally {
                    setIsLoading(false);
                }
            }
        }
    }
    const handleConfigureOrg = async (input: string) => {

        const bearerToken = getSessionItem('bearerToken')
        const adminData = getSessionItem('adminData')
        const parsedata = JSON.parse(adminData);
        if (bearerToken !== '') {
            const token = bearerToken;
            const istokenValid = checkTokenExpiration(token);
            if (istokenValid) {
                try {
                    const adminResponse = await configureOrg(input, token, parsedata, subscriptionData.subscriptionId, resourceGroup.resourceGroupName)
                    const response = await adminResponse.json();
                    if (response.data.role === ROLES.ADMIN) {
                        const adminData = {
                            userName: parsedata.userName,
                            tenantID: response.data.tenantID,
                            userID: response.data.userID,
                            email: response.data.email,
                            token: response.data.token
                        }
                        const slackWorkSpaceData = response.data.slackWorkspaces;
                        const teamDomainData = response.data.teamsAccounts;
                        const lMetrics = response.data.lMetrics;
                        const blobAccount = response.data?.storageAccName ? response.data?.storageAccName : null
                        slackWorkSpaceData ? setSessionItem('slackWorkSpaceData', slackWorkSpaceData) : setSessionItem('teamDomainData', [])
                        teamDomainData ? setSessionItem('teamDomainData', teamDomainData) : setSessionItem('teamDomainData', [])
                        setSessionItem('lMetrics', lMetrics);
                        setSessionItem('adminData', adminData);
                        setSessionItem('blobAccount', blobAccount);
                        setSessionItem('bearerToken', response.data.token)
                        setIsLoading(false);

                        setCurrentStep(AUTH.SUCCESS);
                        if (shouldDisplayCheckbox) {
                            const enterpriseAccount = getSessionItem('isEnterpriseAccount')
                            Cookies.set('checkboxState', String(enterpriseAccount), { expires: 180 });
                        }
                    }
                }
                catch (e: any) {
                    setError(error?.message?.length > 200 ? error.message.slice(0, 110) + '...' : error.message);
                    setIsLoading(false);
                }
            }
            else {
                setIsLoading(false);
            }
        }
    }
    const removeAdminData = () => {
        sessionStorage.clear();
        setCurrentStep(AUTH.ADMINLOGIN)
        navigate("/")
    }
    const handleOutsideClick = (event: any) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setShowPopup(false);
            setError('');
            setNewOption('')

        }

    };
    useEffect(() => {
        if (showPopup) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showPopup]);
    useEffect(() => {
        const bearerToken = getSessionItem('bearerToken')
        if (bearerToken) {
            const token = bearerToken;
            const istokenValid = checkTokenExpiration(token);
            if (istokenValid) {
                const adminData = getSessionItem('adminData');
                const decryptedAdminData = JSON.parse(adminData);
                const azureRMData = getSessionItem('AzureRM');
                const parsedata = JSON.parse(azureRMData);
                const decryptedTempUser = getSessionItem('tempUser')
                if (decryptedTempUser) {
                    setCurrentStep(AUTH.TEMPUSER)
                }
                else if (parsedata?.role === ROLES.AZURE_RM) {
                    setCurrentStep(AUTH.TEMP_USER_CONFIG)
                }

                else if (decryptedAdminData?.role === ROLES.ADMIN) {
                    navigate('/dashboard');
                    // setCurrentStep(AUTH.SUCCESS);
                }
                else if (currentStep === AUTH.FAILED) {
                    sessionStorage.clear()
                    setError("unable to login")
                    return
                }
                else {
                    sessionStorage.clear()
                    setCurrentStep(AUTH.ADMINLOGIN)
                }
            }
            else {
                removeAdminData()
            }
        }
    }, [])
    useEffect(() => {
        const bearerToken = getSessionItem('bearerToken')
        if (bearerToken) {
            const token = bearerToken;
            const istokenValid = checkTokenExpiration(token)
            if (!istokenValid) {
                removeAdminData()
            }
        }
    })
    const handleSave = () => {
        if (subscriptionData.subscriptionId !== '' && resourceGroup.resourceGroupName !== '' && BlobAccount !== '') {
            handleConfigureAccount()
        }
        else {
            setError("Please select all the required fields.")
        }

    };
    const validateName = (name: string) => {
        if (addTo === 'resourceGroup') {
            const resourceGroupRegex = /^[a-zA-Z0-9]([a-zA-Z0-9._()\-]*[a-zA-Z0-9])?$/;
            if (!resourceGroupRegex.test(name) || name.length > 90) {
                setError('Invalid resource group name. It must follow Azure naming rules.');
                return false
            } else {
                setError('');
                return true
            }
        }
        else {
            const blobStorageRegex = /^[a-z0-9]([a-z0-9\-]*[a-z0-9])?$/;
            if (!blobStorageRegex.test(name) || name.length < 3 || name.length > 63) {
                setError('Invalid blob name . Use only lowercase letters and numbers (Max length: 3-63 characters).');
                return false
            } else {
                setError('');
                return true
            }
        }
    }
    const addNewOption = () => {
        if (!newOption.trim() || !region.trim()) {
            setError('Please enter a valid value.');
            return;
        }
        if (region !== '') {

            if (addTo === 'resourceGroup') {
                const flag: boolean = validateName(newOption)
                if (flag) {

                    if (subscriptionData.subscriptionName != "") {
                        if (resourceGroups.some((group) => group.resourceGroupName === newOption)) {
                            setError(`"${newOption}" already exists.Please provide unique name`);
                        } else {
                            handleAddResourceGroup(newOption)
                        }
                    }
                    else {
                        setError("please select a Subscription Name")
                    }
                    setNewOption('')
                    setRegion('')

                    // setResourceGroups((prev) => [...prev, newOptionData]);
                    // setResourceGroupInput(newOption)
                    // clear()
                    return
                }
                else {
                    return
                }

            } else if (addTo === 'blobStorage') {
                const flag: boolean = validateName(newOption)
                if (flag) {
                    if (subscriptionData.subscriptionName != "" && resourceGroup.resourceGroupName != "") {
                        if (blobStorageAccounts.length !== 0 && blobStorageAccounts.some((blob) => blob.storageAccountName === newOption)) {
                            setError(`"${newOption}" already exists.Please provide unique name`);
                        } else {
                            handleAddStorageAccount(newOption)
                        }
                    } else {
                        setError("please select a Subscription Name and Resource Group Name")
                    }
                    setNewOption('')
                    setRegion('')
                    // clear()
                    return
                    // setBlobStorageAccounts((prev) => [...prev, newOptionData]);
                    // setBlobInput(newOption)
                    // clear()
                    // return

                }
                else {
                    return
                }
            }
            else {
                setError("please select a region")
            }
        }

    };
    const clear = () => {
        setNewOption('');
        setShowPopup(false);
        setAddTo('');
    }
    const handleDropdownChange = (selectedOption: any, type: string) => {
        setError('');

        const Name = selectedOption?.label || '';
        const Id = selectedOption?.value || '';

        if (type === 'subscription') {
            if (Id !== '') {
                setSubscriptionData({
                    subscriptionId: Id,
                    subscriptionName: Name,
                    azureResourceGroups: [],
                });
                handleResourceGroup(Id);
            } else {
                setSubscriptionData({
                    subscriptionId: '',
                    subscriptionName: '',
                    azureResourceGroups: [],
                });
                setResourceGroup({
                    resourceGroupId: '',
                    resourceGroupName: ''
                });
                setBlobAccount('');
                setBlobStorageAccounts([]);
                setResourceGroups([]);
            }
        } else if (type === 'region') {
            if (Id !== '') {
                setRegion(Id);
            } else {
                setRegion('');
            }
        } else if (type === 'resource') {
            if (Id !== '') {
                setResourceGroup({
                    resourceGroupId: Id,
                    resourceGroupName: Name
                });
                handleBlobStorageAccount(Id);
            } else {
                setResourceGroup({
                    resourceGroupId: '',
                    resourceGroupName: ''
                });
                setBlobAccount('');
                setBlobStorageAccounts([]);
            }
        } else if (type === 'blob') {
            if (Id !== '') {
                setBlobAccount(Name);
            } else {
                setBlobAccount('');
                setError('Please select a valid option');
            }
        }
    };


    const handleResourceGroup = async (subscriptionId: string) => {

        const adminData = getSessionItem('AzureRM')

        const parsedata = JSON.parse(adminData);
        if (parsedata.token !== '') {
            const token = parsedata.token;
            const istokenValid = checkTokenExpiration(token);
            if (istokenValid) {
                try {
                    setIsLoading(true);
                    const resourceGroupDetails = await GetResourceGroup(parsedata, subscriptionId)
                    const response = await resourceGroupDetails.json();
                    if (resourceGroupDetails) {
                        setBlobStorageAccounts([])
                        setResourceGroups(response.data)
                        if (newOption != '') {
                            const result = response.data.find(
                                (sub: ResourceData) => sub.resourceGroupName.toLowerCase() === newOption.toLowerCase()
                            );
                            result && setResourceGroup(result)
                        }

                    }

                }

                catch (error: any) {
                    setError(error?.message?.length > 200 ? error.message.slice(0, 110) + '...' : error.message);

                }
                finally {
                    setIsLoading(false);
                }
            }
        }
    }
    const handleBlobStorageAccount = async (resourceId: string) => {

        const adminData = getSessionItem('AzureRM')

        const parsedata = JSON.parse(adminData);
        if (parsedata.token !== '') {
            const token = parsedata.token;
            const istokenValid = checkTokenExpiration(token);
            if (istokenValid) {
                try {
                    setIsLoading(true);
                    const resourceGroupDetails = await GetBlobAccount(parsedata, resourceId)
                    const response = await resourceGroupDetails.json();
                    if (resourceGroupDetails) {
                        setBlobStorageAccounts(response.data)
                        if (newOption != '') {
                            setBlobAccount(newOption)
                        }
                    }
                }

                catch (error: any) {
                    setError(error?.message?.length > 200 ? error.message.slice(0, 110) + '...' : error.message);

                }
                finally {
                    clear()
                    setIsLoading(false)
                }
            }
        }
    }
    const handleConfigureAccount = async () => {

        const adminData = getSessionItem('AzureRM')

        const parsedata = JSON.parse(adminData);

        if (parsedata.token !== '') {
            const token = parsedata.token;
            const istokenValid = checkTokenExpiration(token);
            if (istokenValid) {
                try {
                    setIsLoading(true);
                    const resourceGroupDetails = await configureOrganization(parsedata, subscriptionData.subscriptionId, resourceGroup.resourceGroupName, BlobAccount)
                    const response = await resourceGroupDetails.json();
                    if (response) {
                        await handleConfigureOrg(BlobAccount)

                    }


                }

                catch (error: any) {
                    setIsLoading(false);
                    setError(error?.message?.length > 200 ? error.message.slice(0, 110) + '...' : error.message);

                }
                finally {
                    clear()
                    setIsLoading(false);
                }
            }
        }
    }
    const handleAddResourceGroup = async (newOption: string) => {
        const adminData = getSessionItem('AzureRM')
        const parsedata = JSON.parse(adminData);
        if (parsedata.token !== '') {
            const token = parsedata.token;
            const istokenValid = checkTokenExpiration(token);
            if (istokenValid) {
                try {
                    setIsLoading(true);
                    const resourceGroupDetails = await addResourceGroup(parsedata, subscriptionData.subscriptionId, newOption, region)
                    const response = await resourceGroupDetails.json();
                    if (response) {
                        await handleResourceGroup(subscriptionData.subscriptionId)
                    }

                }

                catch (error: any) {

                    setIsLoading(false);
                    setError(error?.message?.length > 200 ? error.message.slice(0, 100) + '...' : error.message);

                }
                finally {
                    clear()
                    setIsLoading(false);
                }
            }
        }
    }
    const handleAddStorageAccount = async (newOption: string) => {
        const adminData = getSessionItem('AzureRM')
        const parsedata = JSON.parse(adminData);
        if (parsedata.token !== '') {
            const token = parsedata.token;
            const istokenValid = checkTokenExpiration(token);
            if (istokenValid) {
                try {
                    setIsLoading(true);
                    const resourceGroupDetails = await addStorageAccountName(parsedata, subscriptionData.subscriptionId, resourceGroup.resourceGroupName, newOption, region)
                    const response = await resourceGroupDetails.json();
                    if (response) {
                        await handleBlobStorageAccount(resourceGroup.resourceGroupId)
                        setIsLoading(false);
                    }

                }

                catch (error: any) {

                    setIsLoading(false);
                    setError(error?.message?.length > 200 ? error.message.slice(0, 100) + '...' : error.message);

                }
                finally {
                    clear()
                    setIsLoading(false);

                }
            }
        }
    }
    const handleTimeOut = () => {
        setTimeout(() => {
            setIsLoading(false);
        }, 5000);
    }
    return (
        <>
            <div className="s2t-main-screen">
                <div className="left-section">
                    <img src={eclipse} alt="eclipses" className="eclipse-left" />
                    <div className='slacktoteamsimage'>
                        <div><img src={slack} className='slackimage' /></div>
                        <div> <img src={to} className='toimage' /></div>
                        <div> <img src={teams} className='teamsimage' /></div>
                    </div>
                    <span className='contenttext'><center>Slack to Teams</center></span>
                    <div className='content'>
                        <span className='content-1'>Simplify transition from Slack to Microsoft Teams with "Saketa Secure Authentication" app, a companion for Saketa Migrator.</span>
                        <span className='content-2'>This app will allow admins to migrate users from Slack to Teams seamlessly without the hassle of manual login requests.</span>
                    </div>
                    <img src={eclipse} alt="eclipses" className="eclipse-right" />
                </div>
                <div className="right-section">
                    <div className="loginheader">
    
                        <img src={saketa} alt="saketa" className="saketalogo" />
                       <div className='saketa-title'>
                        <span className='saketaheader'>Saketa-ASTR
                        </span>
                        <label className='tag-line'>Authenticate Secure Token Registry</label>
                        </div>
                        {isLoading && (
                            <div className="spinner-container">
                                <img
                                    src={spinnerGif}
                                    alt="Loading..."
                                    className="spinner-gif"

                                />
                            </div>
                        )}
                    </div>
                    {currentStep === AUTH.ADMINLOGIN && (
                        <>
                            <div className="login-authenticate">
                                <div className="login-data">
                                    <div className="acc-login">Subscription Login</div>
                                    <div className='acc-desc'>Authenticate with admin credentials</div>
                                    {shouldDisplayCheckbox && <div className='enterprise'><input type="checkbox" checked={isEnterpriseAccount} name="Enterprise Account" onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setIsEnterpriseAccount(event.target.checked) }} className='enterprise-checkbox' /><label>Saketa Storage</label></div>}
                                    <button onClick={handleAdminAccountLogin} className="login"><img src={microsoftlogo} alt="microsoft-logo" className="microsoftlogo" /> Login with your M365 Account</button>
                                </div>
                            </div>
                            {/* {error !== '' ? <div className="error-msg">{error}</div> : ''} */}
                        </>
                    )}
                    {currentStep === AUTH.SUCCESS && (
                        <div className="login-success">
                            <div className="success">Authentication Successful <img src={success} alt="success" className="successgif" onLoad={handleGifLoad} /></div>
                        </div>)}
                    {currentStep === AUTH.BLOB && (
                        <div className="login-failed">
                            <div className="authfail"> Authentication Failed<img src={failed} alt="Failed" className="failedgif" onClick={() => { setCurrentStep(AUTH.ADMINLOGIN); navigate("/"); setError('') }} style={{ cursor: "pointer" }} /></div>
                            <div className="note">
                                <span> You already have access to Azure Blob Storage. Please log in directly to continue
                                </span>
                            </div>
                        </div>)
                    }
                    {currentStep === AUTH.FAILED && (
                        <div className="login-failed">
                            <div className="authfail"> Authentication Failed <img src={failed} alt="Failed" className="failedgif" onClick={() => { setCurrentStep(AUTH.ADMINLOGIN); navigate("/"); setError('') }} style={{ cursor: "pointer" }} /></div>
                            <div className="note">
                                <span>
                                    {error !== '' ? (
                                        error.includes("licensed") ? (
                                            <div>
                                                <span>
                                                    Your organization doesn't have a valid license. Please contact &nbsp;
                                                    <a href="mailto:support@saketa.com">support@saketa.com</a> to get a license key.
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="slack-error-msg" style={{ marginBottom: "1%" }}>{error}</div>
                                        )
                                    ) : null}
                                </span>

                            </div>
                        </div>)
                    }
                    {/* {currentStep === AUTH.NOTLICENSED && (
                        <div className="login-failed">
                            <div className="authfail"> Not Licensed Page <img src={failed} alt="Failed" className="failedgif" onClick={()=>{setCurrentStep(AUTH.ADMINLOGIN);navigate("/")}} style={{cursor:"pointer"}}/></div>
                            <div className="note">
                                <span>Hi <br></br>Your organization doesn't have a valid license. Please contact Saketa Team to get a license key.
                                    <br></br><a href="mailTo:support@saketa.com">support@saketa.com</a> </span>
                            </div>
                        </div>
                    )} */}
                    {(currentStep === AUTH.TEMP_USER_CONFIG || currentStep === AUTH.TEMPUSER) && (
                        <div className='storage'>
                            <div className='storage-confirm'>
                                {currentStep === AUTH.TEMP_USER_CONFIG && (
                                    <>
                                        <span className='storage-text'>Environment Set Up</span>
                                        <div className='storage-container logout-info'>
                                            <div className='storage-subcontainer'>

                                                <label className='label-title'>List Subscriptions:   <span className='asterisk'>*</span> </label>
                                                <Select
                                                    name="AccountName"
                                                    components={{
                                                        DropdownIndicator: () => null,
                                                    }}
                                                    options={[
                                                        { value: "", label: "Select Subscription" },
                                                        ...subscriptions.map((subscription) => ({
                                                            value: subscription.subscriptionId,
                                                            label: subscription.subscriptionName
                                                        })),
                                                    ]}
                                                    value={subscriptionData.subscriptionName !== "" ? {
                                                        value: subscriptionData.subscriptionId,
                                                        label: subscriptionData.subscriptionName,
                                                    } : { value: "", label: "Select Subscription " }}
                                                    onChange={(event: any) => handleDropdownChange(event, 'subscription')}
                                                    isDisabled={false}
                                                    menuPlacement="bottom"
                                                    styles={customStyles}
                                                    isSearchable={false}
                                                />
                                            </div>
                                            <div className='storage-subcontainer'>
                                                <label className='label-title'>Resource Groups:<span className='asterisk'>*</span> </label>
                                                <div className="dropdown-container">
                                                    <Select
                                                        name="ResourceGroups"
                                                        components={{
                                                            DropdownIndicator: () => null,
                                                        }}
                                                        options={[{ value: "", label: "Select Resource group" },
                                                        ...resourceGroups.map((group) => ({
                                                            value: group.resourceGroupId,
                                                            label: group.resourceGroupName,
                                                        }))]}
                                                        onChange={(event: any) => handleDropdownChange(event, 'resource')}
                                                        isDisabled={resourceGroups.length === 0 ? true : false}

                                                        value={resourceGroup.resourceGroupName !== "" ? {
                                                            value: resourceGroup.resourceGroupId,
                                                            label: resourceGroup.resourceGroupName,
                                                        } : { value: "", label: "Select Resource group" }}
                                                        menuPlacement="bottom"
                                                        styles={customStylesGroups}
                                                        isSearchable={false}
                                                    />
                                                </div>
                                                <span className="tooltip-container-temp">
                                                    <button
                                                        className="add-btn"
                                                        onClick={() => {
                                                            setAddTo("resourceGroup");
                                                            setShowPopup(true);
                                                        }}
                                                    >
                                                        +
                                                    </button>
                                                    <span className="tooltip-text-temp">Add Resource group</span>
                                                </span>

                                            </div>

                                            <div className='storage-subcontainer'>
                                                <label className='label-title'>Blob Storage Account: <span className='asterisk'>*</span></label>
                                                <div className="dropdown-container">
                                                    <Select
                                                        name="BlobStorageAccount"
                                                        components={{
                                                            DropdownIndicator: () => null,
                                                        }}
                                                        options={[{ value: "", label: "Select Blob Account" },
                                                        ...blobStorageAccounts.map((blob) => ({
                                                            value: blob.storageAccountName,
                                                            label: blob.storageAccountName
                                                        }))]}
                                                        onChange={(event: any) => handleDropdownChange(event, 'blob')}
                                                        isDisabled={blobStorageAccounts.length === 0 ? true : false}
                                                        menuPlacement="bottom"
                                                        value={BlobAccount != "" ? { value: BlobAccount, label: BlobAccount } : { value: "", label: "Select Blob Storage" }}
                                                        styles={customStylesGroups}
                                                        isSearchable={false}
                                                    />

                                                </div>
                                                <span className="tooltip-container-temp">
                                                    <button
                                                        className="add-btn"
                                                        onClick={() => {
                                                            setAddTo("blobStorage");
                                                            setShowPopup(true);
                                                        }}
                                                    >
                                                        +
                                                    </button>
                                                    <span className="tooltip-text-temp">Add Blob Storage</span>
                                                </span>

                                            </div>


                                            {showPopup && (
                                                <Draggable>
                                                    <div className="temp-popup" ref={dropdownRef}>
                                                        <div className='add-header' style={{ gap: addTo === 'resourceGroup' ? '3rem' : '1.5rem' }}
                                                        ><label className='temp-heading'>{addTo === 'resourceGroup' ? 'RESOURCE GROUP' : 'BLOB STORAGE ACCOUNT'}</label><div onClick={() => { setShowPopup(false); clear(); setError('') }} className="close"> <img src={close} alt="close" /> </div></div>
                                                        <input
                                                            type="text"
                                                            value={newOption}
                                                            onChange={(event) => setNewOption(event.target.value)}
                                                            placeholder={`Enter new ${addTo === 'resourceGroup' ? 'Resource Group' : 'Blob Storage Account'}`}
                                                        />
                                                        {/* <select
                                                            name='Regions'

                                                            onChange={(event: any) => handleDropdownChange(event, 'region')}
                                                            className='region-dropdown'
                                                            value={region}
                                                        >
                                                            <option value="">Select Region</option>
                                                            {regions.map((region) => (
                                                                <option key={region} value={region}>
                                                                    {region}
                                                                </option>
                                                            ))}
                                                        </select> */}
                                                        <Select
                                                            name="Regions"
                                                            options={regions.map((region) => ({
                                                                value: region,
                                                                label: region,
                                                            }))}
                                                            components={{
                                                                DropdownIndicator: () => null,
                                                            }}
                                                            onChange={(selectedOption) => handleDropdownChange(selectedOption, 'region')}
                                                            menuPlacement="bottom"
                                                            styles={{
                                                                container: (base) => ({
                                                                    ...base,
                                                                    width: '100%',
                                                                    color: 'black',
                                                                    cursor: 'pointer',
                                                                    fontSize: 12,
                                                                    textAlign: 'left'

                                                                }),
                                                                menu: (base) => ({
                                                                    ...base,
                                                                    zIndex: 1000,
                                                                    height: 'auto',
                                                                    overflowY: 'auto',
                                                                }),
                                                            }}
                                                            placeholder="Select Region"
                                                            isSearchable={false}
                                                        />
                                                        <button
                                                            onClick={addNewOption}
                                                            className="popup-btn"
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                </Draggable>

                                            )}
                                        </div>

                                        <div className='temp-buttons'>
                                            <button className='temp-proceed' onClick={handleSave}>Configure</button>
                                        </div>
                                        <div className="slack-error-msg" style={{ height: 10, marginBottom: 10 }} title={error?.message ? error.message : error}>{error?.message ? error.message : error}</div>
                                    </>
                                )}
                                {currentStep === AUTH.TEMPUSER && (<div>
                                    <div className="login-data" style={{ marginBottom: 10, marginTop: 0 }}>
                                        <div className="acc-login">Azure Admin Login</div>
                                        <div className='acc-desc'>Authenticate with admin credentials</div>
                                        <button onClick={handleTempUserAccountLogin} className="login"><img src={microsoftlogo} alt="microsoft-logo" className="microsoftlogo" /> Login with your M365 Account</button>

                                    </div>
                                    {error !== '' ? <div className="slack-error-msg">{error}</div> : ''}
                                </div>)}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default AdminLoginScreen;


