import React from 'react';
import './footer.css'; 
const Footer: React.FC = () => {
    return (
        <footer className="footer">
            Powered by Saketa
        </footer>
    );
}

export default Footer;
