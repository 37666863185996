import eclipse from '../../../assets/eclipse.png';
import slack from '../../../assets/slack.png';
import to from '../../../assets/arrow-1.png';
import teams from '../../../assets/teams.png';
import saketa from "../../../assets/saketa.png";
import regret from "../../../assets/failedpic.png"
import success from "../../../assets/success.gif";
import failed from "../../../assets/failed.gif";
import '../slackauthentication/slackauthentication.css';
import { TEAMSACCOUNT } from '../../../common/ApiConfig';
import { useContext, useEffect, useState } from 'react';
import spinnerGif from "../../../assets/saketa-loading.gif";
import { generateOAuthUrl, RejectAPI, TeamsloginUser } from '../../../common/authServices';
import { getSessionItem, setSessionItem } from '../../../common/helper';
import { useSearchParams } from 'react-router-dom';
import { EMAIL } from '../../../common/constants';
import { GlobalStateContext } from '../../../common/GlobalState';

const TeamsAuthentication: React.FC = () => {
    const { currentStep, setCurrentStep } = useContext(GlobalStateContext);
    const [error, setError] = useState<any>('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const [userName, setUserName] = useState('');
    const [isConsent, setIsConsent] = useState<boolean>(false);

    const authenticateTeamsUser = async () => {
        try {
            const oauthUrl = generateOAuthUrl(TEAMSACCOUNT.CLIENT_ID, TEAMSACCOUNT.RESPONSE_TYPE, TEAMSACCOUNT.SCOPES, TEAMSACCOUNT.USER_PROMPT, TEAMSACCOUNT.EMAIL_URL);
            window.location.href = oauthUrl;
        }
        catch (error) {
            console.error('Error during login:', error);
        }
    };
    useEffect(() => {
        const userData = getSessionItem('User');
        const data = getSessionItem("userData");
        const storedData = data && JSON.parse(data);

        if (data) {
            setUserName(storedData.userName);
        }
        const error = getSessionItem('Error');
        const state = getSessionItem('state');
        const code = searchParams.get('code');
        const session_state = searchParams.get('session_state');
        if (code) {
            if (session_state !== null) {
                handleTeamLogin(code);
            }
        }
        if(state){
            setCurrentStep(EMAIL.LOGIN)
        }
        if (error) {
            setError(error);
            setCurrentStep(EMAIL.ERROR);
        }
        else if (userData === 'Authenticated') {
            setCurrentStep(EMAIL.SUCCESS);
        }
        else if (userData === 'Rejected') {
            setCurrentStep(EMAIL.REJECT);
        }
        else {
            setCurrentStep(EMAIL.LOGIN);
        }

    }, [searchParams]);
    const handleTeamLogin = async (code: string) => {
        setIsLoading(true);
        const state = getSessionItem('state');
        const userData = getSessionItem('userData');
        const storedData = userData && JSON.parse(userData);
        var token;
        if (userData) {
            token = storedData.token;
        }
        try {
            const teamsResponse = await TeamsloginUser(token, code, storedData, state);
            const responseData = await teamsResponse.json();
            if (responseData.exception == null) {
                setSessionItem('User', "Authenticated");
                setCurrentStep(EMAIL.SUCCESS);
                setIsLoading(false);
            }
            else {
                if (userData) {
                    setUserName(storedData.userName);
                }
                setCurrentStep(EMAIL.FAILED);
                setError(responseData.exception);
                setIsLoading(false);
                // sessionStorage.clear();
            }
        }
        catch (error: any) {
            if (userData) {
                setUserName(storedData.userName);
            }
            setIsLoading(false);
            setCurrentStep(EMAIL.FAILED);
            setError(error);
            // sessionStorage.clear();
        }
        finally {
            setIsLoading(false);
        }
    }
    const handleRejectionStatus = async () => {
        setIsLoading(true);
        const state = getSessionItem('state');
        const userData = getSessionItem('userData');
        const storedData = JSON.parse(userData);
        const token = storedData.token;
        try {
            setIsLoading(true);
            const slackuserresponse = await RejectAPI(token, storedData, state);
            const response = await slackuserresponse.json();
            if (response.exception == null) {
                setIsLoading(false);
                setSessionItem('User', 'Rejected')
                setCurrentStep(EMAIL.REJECT);
            }
            else {
                if (userData) {
                    setUserName(storedData.userName);
                }
                setCurrentStep(EMAIL.FAILED);
                setError(response.exception);
                // sessionStorage.clear();
                setIsLoading(false);
            }
        }
        catch (error: any) {
            if (userData) {
                setUserName(storedData.userName);
            }
            setCurrentStep(EMAIL.FAILED);
            setIsLoading(false);
            setError(error);
            // sessionStorage.clear();
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="s2t-main-screen">
                <div className="left-section">
                    <img src={eclipse} alt="eclipses" className="eclipse-left" />
                    <div className='slacktoteamsimage'>
                        <div><img src={slack} className='slackimage' alt='slackImage' /></div>
                        <div> <img src={to} className='toimage' alt='ToImage' /></div>
                        <div> <img src={teams} className='teamsimage' alt='teamsImage' /></div>
                    </div>
                    <span className='contenttext'><center>Slack to Teams</center></span>
                    <div className='content'>
                        <span className='content-1'>Simplify transition from Slack to Microsoft Teams with "Saketa Secure Authentication" app, a companion for Saketa Migrator.</span>
                        <span className='content-2'>This app will allow admins to migrate users from Slack to Teams seamlessly without the hassle of manual login requests.</span>
                    </div>
                    <img src={eclipse} alt="eclipses" className="eclipse-right" />
                </div>
                <div className="right-section">
                    <div className="loginheader">
                        <img src={saketa} alt="saketa" className="saketalogo" />
                        <div className='saketa-title'>
                        <span className='saketaheader'>Saketa-ASTR
                        </span>
                        <label className='tag-line'>Authenticate Secure Token Registry</label>
                        </div>
                        {isLoading && (
                            <div className="spinner-container">
                                <img
                                    src={spinnerGif}
                                    alt="Loading..."
                                    className="spinner-gif"

                                />
                            </div>
                        )}
                    </div>
                    {currentStep === EMAIL.LOGIN && (
                        <div className="slackemail-body">
                            <h1 className="auth-required">Authentication Required</h1>
                            <h1 className="user-greet">Hi {userName}</h1>
                            <p className="auth-message">Admin requested you to authenticate into Teams to perform migrations on behalf of you.</p>
                            <div className="note-section"><b>Note</b>: Your credentials will be stored in hosted environment of this web app in an encrypted format to perform migrations on behalf of you.</div>
                            <div className='consent'><input type="checkbox" checked={isConsent} name="Consent" onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setIsConsent(event.target.checked) }} className='consent-checkbox' /><label>I acknowledge and agree to the <a href='https://saketa.com/privacy-policy/' style={{ color: "#FA5F5F" }} target='_blank' rel="noopener noreferrer">privacy policy</a></label></div>
                            <div className="btn-section">
                                <button type="button" onClick={authenticateTeamsUser} className={`${isConsent ? 'consent-active' : 'consent-disabled'} authenticate-btn`} disabled={isConsent ? false : true}>Authenticate</button>
                                <button type="button" className="reject-btn" onClick={handleRejectionStatus} >Reject</button>
                            </div>
                        </div>
                    )}
                    {currentStep === EMAIL.ERROR && (
                        <div className="login-failed">
                            {/* <span>{error !== '' ? <div className="slack-error-msg">{error}</div> : ''}</span> */}
                            <div className="authfail"> Authentication Failed <img src={failed} alt="Failed" className="failedgif" /></div>
                            <div className="note">
                                <span>
                                    Please provide Valid User to Authenticate
                                </span>
                            </div>
                        </div>)
                    }
                    {currentStep === EMAIL.SUCCESS && (
                        <>
                            <img src={success} alt="success" className="success-image" />
                            <h1 className="loginsuccess">Authenticated Successfully</h1>
                        </>
                    )}
                    {currentStep === EMAIL.REJECT && (
                        <>
                            <img src={regret} alt="regret" className="regret-image" />
                            <h1 className="auth-rejected">Authentication Rejected</h1>
                        </>
                    )}
                    {currentStep === EMAIL.FAILED && (
                        <div className="slackemail-body">
                            <h1 className="auth-required">Authentication Failed.</h1>
                            <h1 className="user-greet">Hi {userName}</h1>
                            <p className="auth-message">Admin requested you to authenticate into Teams to perform migrations on behalf of you.</p>
                            <div className="note-section"><b>Note</b>: Your credentials will be stored in hosted environment of this web app in an encrypted format to perform migrations on behalf of you.</div>
                            <span>{error !== '' ? <div className="slack-error-msg">{error?.message}</div> : ''}</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
export default TeamsAuthentication;

