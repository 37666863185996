import { useContext, useEffect, useState } from 'react';
import Slack from '../../../assets/slack.png'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GlobalStateContext } from '../../../common/GlobalState';
import { SLACKACCOUNT } from '../../../common/ApiConfig';
// import { getSlackUsers } from '../../../common/authServices';
import { SLACKAUTH } from '../../../common/constants';
import spinnerGif from '../../../assets/saketa-loading.gif';
import successgif from '../../../assets/success.gif';
import './slacklogin.css'
import { getSessionItem, removeSessionItem, setSessionItem, checkTokenExpiration } from '../../../common/helper';
import { SlackloginUser } from '../../../common/authServices';
import { DataItem } from '../../../common/interfaces';
// import { Cell, Pie, PieChart, PieLabelRenderProps } from 'recharts';
// interface CustomLabelProps extends PieLabelRenderProps {
//     name: string;
//     students: number;
//     color: string
// }
const SlackLoginNew: React.FC = () => {
    const { currentStep, setCurrentStep } = useContext(GlobalStateContext);
    const [error, setError] = useState<any>('');
    const [dropdownError, setDropdownError] = useState<any>('');
    const [data, setData] = useState<DataItem[]>([]);
    const [workspaces, setWorkspaces] = useState<string[]>([]);
    const [selectedWorkspace, setSelectedWorkspace] = useState<string>("");
    const [selectedUserName, setSelectedUserName] = useState<string>("");
    const [filteredUsernames, setFilteredUsernames] = useState<string[]>([]);
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const navigate =useNavigate();
    
    const handleSlackAccountLogin = async () => {
        const authUrl = `https://slack.com/oauth/v2/authorize?client_id=${SLACKACCOUNT.CLIENT_ID}&user_scope=${SLACKACCOUNT.USERSCOPES}&redirect_uri=${encodeURIComponent(SLACKACCOUNT.REDIRECT_URI)}`;
        window.location.href = authUrl;
    };

    useEffect(() => {
        const bearerToken = getSessionItem('bearerToken')
        if (bearerToken) {
            const token = bearerToken;
            const istokenValid = checkTokenExpiration(token)
            if (!istokenValid) {
                removeAdminData()
            }
        }
    })

    const handleCurrentGifLoad = () => {
        setTimeout(() => {
            setCurrentStep(SLACKAUTH.TEAMSLOGIN);
        }, 2000);
    };
    const removeAdminData = () => {
        sessionStorage.clear();
        navigate('/');
    }

    const handleSlackWorkspace = async(workspace:string,userName:string) =>{
        const slackWorkSpaceData= getSessionItem('slackWorkSpaceData');
        const parseData = slackWorkSpaceData ? JSON.parse(slackWorkSpaceData):'';
        const filteredUsers = parseData ? parseData.filter((user: { workspaceName: string; userName: string; }) => {
            return (                
                    user.workspaceName ===  workspace&& 
                    user.userName ==  userName
                   
                );
            }): []
            
        if(filteredUsers.length>0){
            setSessionItem("SlackLoginData",filteredUsers[0]);
            setCurrentStep(SLACKAUTH.SUCCESS);
            setSelectedUserName("");
            setFilteredUsernames([]);
            setSelectedWorkspace("");
        }       
        else{
            setDropdownError("please select the required option");
        } 
    }

    const handleSlackUsers = async (code: string) => {
        setIsLoading(true);
        const state = null;
        const token = getSessionItem('bearerToken');
        const adminData = getSessionItem('adminData');
        const storedData = JSON.parse(adminData);
        try {
            setIsLoading(true);
            const slackuserresponse = await SlackloginUser(token,code,storedData,state);
            const response = await slackuserresponse.json();
            if (response.exception == null) {
                setSessionItem("SlackLoginData", response.data);
                setCurrentStep(SLACKAUTH.SUCCESS);
                const slackData = {
                    email: response.data.email,
                    userID: response.data.userID,
                    userName: response.data.userName,
                    workspaceID:  response.data.workspaceID,
                    workspaceName: response.data.workspaceName
                }
                const slackWorkSpaceData= getSessionItem('slackWorkSpaceData');
                const parseData = slackWorkSpaceData && JSON.parse(slackWorkSpaceData);
                if(parseData.length===0){
                    const slackWorkspace = [slackData];
                    setSessionItem('slackWorkSpaceData',slackWorkspace)
                }
                else{
                    if (parseData && parseData.length > 0) {
                        let addedWorkspace = [...parseData];  
                        const isExisting = addedWorkspace.some((user: any) =>                             
                            user.workspaceName === slackData.workspaceName && user.userID === slackData.userID
                        );        
                        if (!isExisting) {
                            addedWorkspace.push(slackData); 
                        }                    
                        setSessionItem('slackWorkSpaceData', JSON.stringify(addedWorkspace));
                    }                     
                } 
            }
            else {
                setCurrentStep(SLACKAUTH.SLACKLOGIN);
                setError(response.exception);
            }
        }
        catch (error: any) {
            setCurrentStep(SLACKAUTH.SLACKLOGIN);
            setError(error);
            return
        }
        finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        const error = searchParams.get('error');
        if (error) {
            setError(error);
            setCurrentStep(SLACKAUTH.SLACKLOGIN);
            return
        }
        const code = searchParams.get('code');
        const isGenerateSASKey=getSessionItem('isGenerateSASKey');
        const session_state = searchParams.get('session_state');
        if (code) {
            if (session_state==null && !isGenerateSASKey) {
                handleSlackUsers(code);
            }
        }
    }, [searchParams]);
    useEffect(() => {
        const adminData = getSessionItem('adminData');
        const slackLoginData = getSessionItem('SlackLoginData');
        const teamLoginData = getSessionItem('TeamLoginData');
        if (teamLoginData) {
            navigate('/dashboardchannel');
        }
        else if (slackLoginData) {
            setCurrentStep(SLACKAUTH.TEAMSLOGIN)
            navigate('/dashboard');
        }
        else if (adminData) {
            setCurrentStep(SLACKAUTH.SLACKLOGIN)
            navigate('/dashboard');
        }
        else {
            navigate('/');
        }
    }, [searchParams]);   

    useEffect(() => {
        const slackWorkSpaceData= getSessionItem('slackWorkSpaceData');
        const response: DataItem[] = slackWorkSpaceData? JSON.parse(slackWorkSpaceData):[];
        setData(response);
        const uniqueWorkspaces = Array.from(
            new Set(response.map((item) => item.workspaceName))
          );
          setWorkspaces(uniqueWorkspaces);
    }, [currentStep]);

    const handleWorkspaceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDropdownError("")
        const workspace = event.target.value;
        setSelectedWorkspace(workspace);
        const usernames = data
          .filter((item) => item.workspaceName === workspace)
          .map((item) => item.userName);
        const uniqueUsernames = Array.from(new Set(usernames));
        setFilteredUsernames(uniqueUsernames);
    };
      

    return (
        <>
            {isLoading && (
                <div className="spinner-container">
                    <img
                        src={spinnerGif}
                        alt="Loading..."
                        className="spinner-gif"
                    />
                </div>
            )}
            {currentStep===SLACKAUTH.SLACKLOGIN &&
            <>
                <div className='slack-container'>
                    <div className="slacklogin">
                        {workspaces.length>0 && <> <span> Login with available workspaces</span>
                        <div className='workspaceCheck'> 
                            <div className=''>
                                <select id="workspace" className='username' value={selectedWorkspace} onChange={handleWorkspaceChange}>
                                    <option value="">Select Workspace</option>
                                    {workspaces.map((workspace) => (
                                    <option key={workspace} value={workspace} className="key-box"
                                    title={workspace}>
                                        {workspace.length>18 ? workspace.slice(0,18)+'...':workspace}
                                    </option>
                                    ))}
                                </select>
                            </div>
                            <div className=''>
                                {filteredUsernames.length===1 &&  <select  className='username' id="username"  disabled={!selectedWorkspace} value={selectedUserName} >
                                    {filteredUsernames.map((username) => (
                                    <option key={username} value={username} className="key-box"
                                    title={username}>
                                        {username.length>18 ?username.slice(0,18)+'...':username}
                                    </option>
                                    
                                    ))}
                                </select>}
                                {filteredUsernames.length!=1 &&  <select  className='username' id="username"   disabled={!selectedWorkspace} onChange={(e) => setSelectedUserName(e.target.value)} style={{ cursor: filteredUsernames.length>0 ? 'pointer' : 'not-allowed'}}>
                                    <option value="">Select Username</option>
                                    {filteredUsernames.map((username) => (
                                    <option key={username} value={username} className="key-box" title={username}>
                                        {username.length>18 ?username.slice(0,18)+'...':username}
                                    </option>
                                    ))}
                                </select>}
                            </div>
                            <span><button className='connect' onClick={() => handleSlackWorkspace(selectedWorkspace,filteredUsernames.length>1?selectedUserName:filteredUsernames[0])}>Connect</button></span>
                        </div>
                        <span>{dropdownError !== '' ? <div className="slack-error-msg">{dropdownError}</div> : ''}</span>
                        <div className="separator">OR</div></>}
                        <span className='signin'>Sign in to
                            <img src={Slack} alt="slack" className="slacklogo" />
                            <span className='slack'>Slack</span>
                        </span>
                        <div className='slacktext'>Login with your Slack admin account</div>
                        <button className='slackbutton' onClick={handleSlackAccountLogin}>Sign in with Slack</button>
                        <span>{error !== '' ? <div className="slack-error-msg">{error?.message}</div> : ''}</span>
                    </div>
                </div>
                
                
            </>}
            {currentStep === SLACKAUTH.SUCCESS &&
                <div className='slackloginsuccess'>
                    <img src={successgif} alt="success gif" className='slacksuccessgif' onLoad={handleCurrentGifLoad} />
                    <span className='successText'>Slack Login Successful</span>
                </div>
            }
        </>

    );
}
export default SlackLoginNew;

