import React, { createContext, useState, ReactNode } from 'react';
import { AUTH } from './constants';
import { TeamUsers } from './interfaces';
import { getSessionItem } from './helper';

interface GlobalStateProps {
    adminUserName: string;
    setAdminUserName: (name: string) => void;
    adminUserEmailId: string;
    setAdminUserEmailId: (email: string) => void;
    currentStep:string;
    setCurrentStep:(step: string) => void;
    slackUserEmailId: string;
    setSlackUserEmailId: (token: string) => void;
    slackUserId: string;
    setSlackUserId: (token: string) => void;
    teamsUserEmailId: string;
    setTeamsUserEmailId: (token: string) => void;
    isLogout:boolean;
    setIsLogout:(isLogout:boolean)=>void;
    adminTenantId:string;
    setAdminTenantId:(adminTenantId:string) =>void;
    adminUserId:string;
    setAdminUserId:(adminUserId:string) =>void;
    workspaceID:string;
    setWorkspaceId:(workspaceID:string) => void;
    tenantID:string;
    setTenantId:(tenantID:string) => void;
    teamsUserId:string;
    setTeamsUserId:(teamsUserId:string)=>void;
}


interface GlobalStateProviderProps {
    children: ReactNode;
}

// Create context
export const GlobalStateContext = createContext<GlobalStateProps | any>(undefined);

export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ children }) => {
    const [adminUserName, setAdminUserName] = useState<string>('');
    const [adminUserEmailId, setAdminUserEmailId] = useState<string>('');
    const [currentStep, setCurrentStep] = useState<string>('');
    const [slackUserEmailId, setSlackUserEmailId] = useState<string>('');
    const [slackUserId, setSlackUserId] = useState<string>('');
    const [teamsUserEmailId, setTeamsUserEmailId] = useState<string>();
    const [isLogout,setIsLogout]=useState<boolean>(false)
    const [adminUserId,setAdminUserId] = useState<string>();
    const [adminTenantId,setAdminTenantId] = useState<string>();
    const [workspaceID,setWorkspaceId] = useState<string>();
    const [tenantID,setTenantId] = useState<string>();
    const [teamsUserId,setTeamsUserId] = useState<string>();
    // const [,] = useState<string>();
    const slackLoginData = getSessionItem('SlackLoginData');
    const adminUser = getSessionItem('adminData');
    const teamsLoginData =  getSessionItem('TeamLoginData');

    React.useEffect(() => {
        const slackLoginData = getSessionItem('SlackLoginData');
        const adminUser = getSessionItem('adminData');
        const teamsLoginData =  getSessionItem('TeamLoginData');
        if (slackLoginData) {
            const parsedUser = JSON.parse(slackLoginData);
            setSlackUserEmailId(parsedUser.email || '')
            setSlackUserId(parsedUser.userID || '')
            setWorkspaceId(parsedUser.workspaceID || '')
        }
        if(teamsLoginData){
            const parsedUser = JSON.parse(teamsLoginData);
            setTeamsUserEmailId(parsedUser.email || '')
            setTenantId(parsedUser.tenantID ||'')
            setTeamsUserId(parsedUser.userID || '')
        }
        if(adminUser){
          const parsedAdminUser = JSON.parse(adminUser)
          setAdminUserName(parsedAdminUser.userName|| ''  )
          setAdminUserEmailId(parsedAdminUser.email|| '')
          setAdminUserId(parsedAdminUser.userID || '')
          setAdminTenantId(parsedAdminUser.tenantID || '')
        }
      }, [adminUser,slackLoginData,teamsLoginData]);
    const contextValue = {
        adminUserName, setAdminUserName, adminUserEmailId, setAdminUserEmailId,currentStep,setCurrentStep,
        slackUserEmailId, setSlackUserEmailId, slackUserId,setSlackUserId,teamsUserEmailId,setTeamsUserEmailId,
        isLogout,setIsLogout,adminUserId,setAdminUserId,adminTenantId,setAdminTenantId,workspaceID,setWorkspaceId,
        tenantID,setTenantId,teamsUserId,setTeamsUserId
    };

    return (
        <GlobalStateContext.Provider value={contextValue}>
            {children}
        </GlobalStateContext.Provider>
    );
};
