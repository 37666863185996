import { AES, enc } from 'crypto-ts';
import { AUTH } from './constants';
import { GlobalStateContext } from './GlobalState';
import { useContext } from 'react';
import { jwtDecode } from 'jwt-decode';
// import { SECRET_KEY } from './ApiConfig';
export const encryptData = (data: any): string => {
    if (typeof process.env.REACT_APP_SECRET_KEY === "string") {
        return AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
    } else {
        return AES.encrypt(JSON.stringify(data), "").toString();
    }
};
export const decryptData = (cipherText: string): any => {
    if (typeof process.env.REACT_APP_SECRET_KEY === "string") {
        const bytes = AES.decrypt(cipherText, process.env.REACT_APP_SECRET_KEY);
        const parsedBytes = bytes.toString(enc.Utf8);
        return JSON.parse(parsedBytes);
    } else {
        const bytes = AES.decrypt(cipherText, "");
        const parsedBytes = bytes.toString(enc.Utf8);
        return JSON.parse(parsedBytes);
    }
};

export const setSessionItem = (key: string, value: any) => {
    if (typeof value === "object") {
        sessionStorage.setItem(key, encryptData(JSON.stringify(value)));
    } else {
        sessionStorage.setItem(key, encryptData(value));
    }
};


export const getSessionItem = (key: string): any => {
    const item = sessionStorage.getItem(key);
    // const decryptedItem = item && decryptData(item);
    // return decryptedItem ? JSON.parse(item) : null;
    if (item) {
        return decryptData(item);
    }
    return null;
};

export const removeSessionItem = (key: string) => {
    sessionStorage.removeItem(key);
};

export const getInitials = (name: string) => {
    if (!name) return '';
    const names = name.split(' ');
    const initials = names.map(n => n[0]).join('');
    return initials.toUpperCase();
};

export const checkTokenExpiration = (token: string): boolean => {
    if (!token) {
        return false;
    }

    try {
        const decoded = jwtDecode(token)
        const currentTime = Date.now() / 1000;
        if (!decoded.exp || decoded.exp < currentTime) {
            return false
        }
        return true
    } catch (error) {
        return false
    }
}