import eclipse from '../../../assets/eclipse.png';
import slack from '../../../assets/slack.png';
import to from '../../../assets/arrow-1.png';
import teams from '../../../assets/teams.png';
import saketa from "../../../assets/saketa.png";
import regret from "../../../assets/failedpic.png"
import success from "../../../assets/success.gif";
import failed from "../../../assets/failed.gif";
import '../slackauthentication/slackauthentication.css';
import  {  SLACKACCOUNT } from '../../../common/ApiConfig';
import { useContext, useEffect, useState } from 'react';
import spinnerGif from "../../../assets/saketa-loading.gif";
import { EMAIL } from '../../../common/constants';
import {  RejectAPI, SlackloginUser } from '../../../common/authServices';
import { getSessionItem, setSessionItem} from '../../../common/helper';
import { GlobalStateContext } from '../../../common/GlobalState';
import { useSearchParams } from 'react-router-dom';

const SlackAuthentication: React.FC = () => {
    const { currentStep, setCurrentStep } = useContext(GlobalStateContext); 
    const [error, setError] = useState<any>('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const [userName,setUserName] = useState('');   
    const [workspaceName,setWorkspaceName] = useState('');
    const [isConsent, setIsConsent] = useState<boolean>(false);
    

    const authenticateSlackUser = async () => {
        const authUrl = `https://slack.com/oauth/v2/authorize?client_id=${SLACKACCOUNT.CLIENT_ID}&user_scope=${SLACKACCOUNT.USERSCOPES}&redirect_uri=${encodeURIComponent(SLACKACCOUNT.EMAIL_URL)}`;
        window.location.href = authUrl;
    };

    useEffect(() => {
        const userData = getSessionItem('User');
        const data = getSessionItem("userData");
        const storedData =data && JSON.parse(data);
        if(data){
            setUserName(storedData.userName);
            setWorkspaceName(storedData.workspace);
        }
        const state = getSessionItem('state');
        const error = getSessionItem('Error');   
        const code = searchParams.get('code');
        const session_state = searchParams.get('session_state');
        if (code) {
            if (session_state===null) {
                handleSlackUsers(code);
            }           
        }
        if(state){
            setCurrentStep(EMAIL.LOGIN)
        }
        if(error){
            setError(error);
            setCurrentStep(EMAIL.ERROR);
        }
        else if(userData === 'Authenticated'){
            setCurrentStep(EMAIL.SUCCESS);
        }
        else if (userData === 'Rejected'){
            setCurrentStep(EMAIL.REJECT);
        }
            
    }, [searchParams]);

    const handleSlackUsers = async (code: string) => {
        setIsLoading(true);
        const state = getSessionItem('state');
        const userData= getSessionItem('userData');
        const storedData =userData && JSON.parse(userData);
        var token;
        if(userData){
            
            token = storedData.token;
        }
        
        try{
            setIsLoading(true);
            if(token){
            const slackuserresponse = await SlackloginUser(token,code,storedData,state);
            const response = await slackuserresponse.json();
            if(response.exception == null){
                setSessionItem('User',"Authenticated");
                setCurrentStep(EMAIL.SUCCESS);
            }
            else {
                if(userData){ 
                    setUserName(storedData.userName);
                    setWorkspaceName(storedData.workspace);
                }
                setCurrentStep(EMAIL.FAILED);
                setError(response.exception);
                // sessionStorage.clear();
                setIsLoading(false);
            }
            }
            
        }
        catch (error:any) {
            if(userData){ 
                setUserName(storedData.userName);
                setWorkspaceName(storedData.workspace);
            }
            setCurrentStep(EMAIL.FAILED);
            setError(error);
            // sessionStorage.clear();
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleRejectionStatus = async () => {        
        setIsLoading(true);
        const state = getSessionItem('state');
        const userData= getSessionItem('userData');
        const storedData =userData && JSON.parse(userData);
        const token = storedData.token;
        try{
            setIsLoading(true);
            const slackuserresponse = await RejectAPI(token,storedData,state);
            const response = await slackuserresponse.json();
            if(response.exception == null){
                setSessionItem('User','Rejected')
                setCurrentStep(EMAIL.REJECT);
                setIsLoading(false);
            }
            else {
                if(userData){ 
                    setUserName(storedData.userName);
                    setWorkspaceName(storedData.workspace);
                }
                setCurrentStep(EMAIL.FAILED);
                setError(response.exception);
                // sessionStorage.clear();
                setIsLoading(false);
            }
        }
        catch (error:any) {
            if(userData){ 
                setUserName(storedData.userName);
                setWorkspaceName(storedData.workspace);
            }
            setCurrentStep(EMAIL.FAILED);
            setError(error);
            // sessionStorage.clear();
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    }
    
    return (
        <>
            <div className="s2t-main-screen">
                <div className="left-section">
                    <img src={eclipse} alt="eclipses" className="eclipse-left" />
                    <div className='slacktoteamsimage'>
                        <div><img src={slack} className='slackimage' alt='slackImage'/></div>
                        <div> <img src={to}  className='toimage' alt='ToImage'/></div>
                        <div> <img src={teams} className='teamsimage' alt='teamsImage'/></div>
                    </div>
                    <span className='contenttext'><center>Slack to Teams</center></span>
                    <div className='content'>
                        <span className='content-1'>Simplify transition from Slack to Microsoft Teams with "Saketa Secure Authentication" app, a companion for Saketa Migrator.</span>
                        <span className='content-2'>This app will allow admins to migrate users from Slack to Teams seamlessly without the hassle of manual login requests.</span>
                    </div>
                    <img src={eclipse} alt="eclipses" className="eclipse-right" />
                </div>
                <div className="right-section">
                    <div className="loginheader">
                        <img src={saketa} alt="saketa" className="saketalogo" />
                        <div className='saketa-title'>
                        <span className='saketaheader'>Saketa-ASTR
                        </span>
                        <label className='tag-line'>Authenticate Secure Token Registry</label>
                        </div>
                        {isLoading && (
                            <div className="spinner-container">
                                <img
                                    src={spinnerGif}
                                    alt="Loading..."
                                    className="spinner-gif"

                                />
                            </div>
                        )}
                    </div>
                    {currentStep === EMAIL.LOGIN && (
                        <div className="slackemail-body">
                            <h1 className="auth-required">Authentication Required</h1>
                            <h1 className="user-greet">Hi {userName}</h1>
                            <p className="auth-message">Admin requested you to authenticate into {workspaceName}  Slack workspace to perform migrations on behalf of you.</p>
                            <div className="note-section"><b>Note</b>: Your credentials will be stored in hosted environment of this web app in an encrypted format to perform migrations on behalf of you.</div>
                            <div className='consent'><input type="checkbox" checked={isConsent} name="Consent" onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setIsConsent(event.target.checked) }} className='consent-checkbox' /><label>I acknowledge and agree to the <a href='https://saketa.com/privacy-policy/' style={{color:"#FA5F5F"}} target='_blank' rel="noopener noreferrer">privacy policy</a></label></div>
                            <div className="btn-section">
                                <button type="button"  onClick={authenticateSlackUser} className={`${isConsent ? 'consent-active' : 'consent-disabled'} authenticate-btn`} disabled={isConsent?false:true}>Authenticate</button>
                                <button type="button" className="reject-btn" onClick={handleRejectionStatus} >Reject</button>
                            </div>
                        </div>
                    )}
                    {currentStep === EMAIL.SUCCESS && (
                        <>
                            <img src={success} alt="success" className="success-image" />
                            <h1 className="loginsuccess">Authenticated Successfully</h1>
                        </>
                    )}
                    {currentStep === EMAIL.REJECT && (
                        <>
                            <img src={regret} alt="regret" className="regret-image" />
                            <h1 className="auth-rejected">Authentication Rejected</h1>
                        </>
                    )}
                    {currentStep === EMAIL.ERROR && (
                        <div className="login-failed">
                           
                            <div className="authfail"> Authentication Failed <img src={failed} alt="Failed" className="failedgif"  /></div>
                            <div className="note">
                                <span>{error !== '' ? <div className="slack-error-msg">{error}</div> : ''}</span>
                            </div>
                        </div>)
                    }
                    {currentStep === EMAIL.FAILED && (
                        <div className="slackemail-body">
                            {/* <p className="exception">{exception != null ? exception : ''}</p> */}
                            <h1 className="auth-required">Authentication Failed.</h1>
                            <h1 className="user-greet">Hi {userName} </h1>
                            <p className="auth-message">Admin requested you to authenticate into {workspaceName}  Slack workspace to perform migrations on behalf of you.</p>
                            <div className="note-section"><b>Note</b>: Your credentials will be stored in hosted environment of this web app in an encrypted format to perform migrations on behalf of you.</div>
                            <span>{error !== '' ? <div className="authenticate-error-msg">{error?.message}</div> : ''}</span>    
                        </div>
                    )}
                </div>
            </div>      
        </>
    )
}

export default SlackAuthentication;
    