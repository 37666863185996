export const APIURL = process.env.REACT_APP_APIURL?process.env.REACT_APP_APIURL:"";
export const APIURLENTERPRISE = process.env.REACT_APP_APIURLENTERPRISE?process.env.REACT_APP_APIURLENTERPRISE:"";
export const APITEMPUSER = process.env.REACT_APP_TEMPUSER?process.env.REACT_APP_TEMPUSER:"";
export const APIEMAILUSER = process.env.REACT_APP_APIUSERURL?process.env.REACT_APP_APIUSERURL:"";

// **Authentication Details**
export const ADMINACCOUNT ={
    CLIENT_ID : 'f47d7d42-7668-4850-a220-91a9ee50cc81',
    SCOPES: 'https://storage.azure.com/.default',
    SCOPES_ENTERPRISE:"openId%20profile",
    RESPONSE_TYPE : 'code',
    PROMPT : 'select_account',
    REDIRECT_URI: process.env.REACT_APP_ADMIN_REDIRECT_URI ? process.env.REACT_APP_ADMIN_REDIRECT_URI :''
};
// * Slack Details *
export const SLACKACCOUNT ={
    CLIENT_ID: "7018124503777.7420792338145",
    REDIRECT_URI : process.env.REACT_APP_REDIRECT_URI  ? process.env.REACT_APP_REDIRECT_URI: "",
    USERSCOPES: "calls:read,channels:history,channels:read,dnd:read,emoji:read,files:read,groups:history,groups:read,identify,im:history,im:read,links:read,mpim:history,mpim:read,pins:read,reactions:read,reminders:read,remote_files:read,remote_files:share,search:read,stars:read,team:read,usergroups:read,users.profile:read,users:read,users:read.email",
    // BOTSCOPES : "commands,chat:write,im:write,mpim:write,calls:read,channels:history,channels:read,groups:read,users:read",
    EMAIL_URL:process.env.REACT_APP_SLACK_EMAIL_URL? process.env.REACT_APP_SLACK_EMAIL_URL:""
}
export const TEAMSACCOUNT ={
    CLIENT_ID : 'f49e3ae3-5e04-4a67-b6e0-965e706fa03e',
    SCOPES: "offline_access https://graph.microsoft.com/.default",
    RESPONSE_TYPE : 'code',
    PROMPT: 'consent',
    USER_PROMPT : 'select_account',
    REDIRECT_URI:  process.env.REACT_APP_REDIRECT_URI?  process.env.REACT_APP_REDIRECT_URI:"",
    EMAIL_URL: process.env.REACT_APP_TEAMS_EMAIL_URL?  process.env.REACT_APP_TEAMS_EMAIL_URL:""
}
export const TEMPUSER={
    SCOPES:"https://management.azure.com/.default"

}
export default APIURL;