import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { GlobalStateProvider } from './common/GlobalState';
import AdminLoginScreen from './components/admin/adminloginscreen';
import DashBoard from './components/dashboard/dashboard';
import SlackLoginNew from './components/dashboard/slack/slacklogin';
import DashBoardChannel from './components/dashboardchannel/dashboardchannel';
import SlackAuthentication from './components/dashboardchannel/slackauthentication/slackauthentication';
import TeamsAuthentication from './components/dashboardchannel/teamsauthentication/teamsauthentication';
import Authentication from './components/dashboardchannel/authentication';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <GlobalStateProvider>
      <BrowserRouter>
        <Routes>
        <Route index element={<AdminLoginScreen />} />
        <Route path="/dashboard" element={<DashBoard />} />
        <Route path="/slackloginnew" element={<SlackLoginNew />} />
        <Route path="/dashboardchannel" element={<DashBoardChannel />} />
        <Route path="/slackauthentication" element={<SlackAuthentication />} />
        <Route path="/authentication" element={<Authentication />} />
        <Route path="/teamsauthentication" element={<TeamsAuthentication />} />
      </Routes>
      </BrowserRouter>
    </GlobalStateProvider>
);

reportWebVitals();
